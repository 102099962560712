import React, { useState, useContext, useRef, useEffect } from "react";

import RecordRTC from "recordrtc";
import EmojiPicker from "emoji-picker-react";
import Picker from "emoji-picker-react";

import { RootStore } from "../../../store/store";
import { usePaymentContext } from "../../../context/PaymentContext";
import { FFmpeg } from "@ffmpeg/ffmpeg";
import { useDropzone } from "react-dropzone";
import { fetchFile } from "@ffmpeg/util";
import Nouislider from "nouislider-react";
import "nouislider/dist/nouislider.css";

import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import "./ChatContent.scss";
import moment from "moment";
import { useParams } from "react-router-dom";
import AssetRender from "./assetRender";
import axios, { BaseURL } from "../../../api/axios";
import WhiteBoard from "./WhiteBoard";
import NoImage from "../../../assets/images/chat-images/no-image.png";
import FilerobotImageEditor, {
  TABS,
  TOOLS,
} from "react-filerobot-image-editor";
import LoadingImage from "../../../assets/images/chat-images/loading.svg";
import DeleteConfirmation from "./DeleteConfirmation";
import Progressbar from "./Progressbar";
import { useModalContext } from "../../WebinarAdminCreateWebinarTab/ModalContext";
import ImageEditorModal from "../ChatProfile/ImageEditorModal";
import WebSocketService from "../../../webSocketService";
import Swal from "sweetalert2";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import MiniAssetRender from "./MiniAssetRender";
import { Rnd } from "react-rnd";

const mimeType = "video/webm";

const ChatContent = (props) => {
  const routeParams = useParams();
  const [store, setStore] = useContext(RootStore);
  const { chatStore, userStore, socketConnection } = store;
  const { chatHistory, groups } = chatStore;
  const { profile } = userStore;

  //   console.log("profile",profile);

  const {
    connectToWebSocket,
    sendMessage,
    sendReactionToMessage,
    deleteMessage,
    closeWebSocket,
  } = WebSocketService();

  const {
    isSearchOverlayOpen,
    setIsSearchOverlayOpen,
    toggleSearchOverlay,
    setMessageRefs,
    highlightedMessageId,
    chatImageEditURL,
    isImageEditorOpen,
    setIsImageEditorOpen,
    chatImageEditedResult,
    setChatImageEditURL,
    toggleGroupInfoOverlay,
    isGroupInfoOverlayOpen,
    setIsGroupInfoOverlayOpen,
    toggleAllMediaOverlay,
    searchTerm,
    setSearchTerm,
    messageRefs,
    setHighlightedMessageId,
    closeAllVoters,
    isScrollToBottom,
    setIsScrollToBottom,
    activeChatRoomGlobal,
    setActiveChatRoomGloabal,
    isSynopsisVisible,
    setIsSynopsisVisible,
    synopsisData,
    setSynopsisData,
    isViewMoreMenuOpen,
    setIsViewMoreMenuOpen,
    isSearchActive,
    setIsSearchActive,
    isArchived,
    setIsArchived,
    handleSidebar,
    chatSidebarOpen,
    chatSearchActive,
    setChatSearchActive,
    handleChatSearch,
    chatProfileOpen,
    setChatProfileOpen,
    handleChatProfileOpen,
    handleGroupInfoClick,
  } = useModalContext();

  const [activeChatGroupData, setActiveChatGroupData] = useState(null);
  const { connection, ioState } = socketConnection;
  const [tooltipText, setTooltipText] = useState(false);
  const [progress, setProgress] = useState(0);
  const [shareModal, setShareModal] = useState(false);
  const [chatMessage, setChatMessage] = useState("");
  const [addAttachActive, setAddAttachActive] = useState(false);
  const [openCamModal, setOpenCamModal] = useState(false);
  const [openWhiteBoardModal, setOpenWhiteBoardModal] = useState(false);
  const [activeCaseName, setActiveCaseName] = useState("");
  const [openImageModal, setOpenImageModal] = useState(false);
  const [openVideoModal, setOpenVideoModal] = useState(false);
  const [openAttachDocsModal, setOpenAttachDocsModal] = useState(false);
  const [openImageGalleryModal, setOpenImageGalleryModal] = useState(false);
  const [videoThumbnail, setVideoThumbnail] = useState(null);
  const [selectedImage, setSelectedImage] = useState([] || {});
  const [selectedDoc, setSelectedDoc] = useState([]);
  const [sendingImg, setSendingImg] = useState(false);
  // const [isTyping, setIsTyping] = useState(false);
  // const [typingMessage, setTypingMessage] = useState({});
  // const [permission, setPermission] = useState(false);
  const [nextButtonDisable, setNextButtonDisable] = useState(true);
  const [prevButtonDisable, setPreButtonDisable] = useState(true);
  // const [recordingStatus, setRecordingStatus] = useState("inactive");
  // const [stream, setStream] = useState(null);
  // const [videoChunks, setVideoChunks] = useState([]);
  const [recordedVideo, setRecordedVideo] = useState(null);
  const [isUpload, setIsUpload] = useState(false);
  const [carouselActiveIndex, setCarouselActiveIndex] = useState(0);
  const [disableUploadBtn, setDisableUploadBtn] = useState(false);
  const [convertedVideoURL, setConvertedVideoURL] = useState(null);
  const [answerPrivacy, setAnswerPrivacy] = useState(false);

  const [uploaded, setUploaded] = useState(null);
  const mediaRecorder = useRef(null);
  const liveVideoFeedRef = useRef(null);

  const [activeRooms, setActiveRooms] = useState(false);
  // const [isArchived, setIsArchived] = useState(false);
  const [onlyAchive, setOnlyAchive] = useState(false);

  const [textareaHeight, setTextareaHeight] = useState(50);
  const [showModal, setShowModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  const [isTrimming, setIsTrimming] = useState(false);
  const [trimRange, setTrimRange] = useState([0, 10]);
  const [videoDuration, setVideoDuration] = useState(0);
  const [isTrimmedVideoConverted, setIsTrimmedVideoConverted] = useState(null);

  const [audioPermission, setAudioPermission] = useState(false);
  const [audioRecordingStatus, setAudioRecordingStatus] = useState("inactive");
  const [recordedAudio, setRecordedAudio] = useState(null);
  const [openAudioModal, setOpenAudioModal] = useState(false);

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [question, setQuestion] = useState("");
  const [options, setOptions] = useState(["", ""]);
  const [duplicateError, setDuplicateError] = useState(null);
  // const [isViewMoreMenuOpen, setIsViewMoreMenuOpen] = useState(false);
  const [isSearchMessageByUsernamePopop, setIsSearchMessageByUsernamePopop] =
    useState(false);
  const [groupUsers, setGroupUsers] = useState([]);
  const [filteredUser, setFilteredUser] = useState(null);
  const [filterByQuestion, setFilterByQuestion] = useState(false);
  const [savedEditedImage, setSavedEditedImage] = useState(null);
  const [showSavedEditedImage, setShowSavedEditedImage] = useState(false);

  const [clinicalNote, setClinicalNote] = useState("");

  const menuRef = useRef(null);

  // const mediaRecorderRef = useRef(null);
  const liveAudioFeedRef = useRef(null);
  const audioChunks = useRef([]);

  const [filteredMessages, setFilteredMessages] = useState([]);

  const [showMessageMenu, setShowMessageMenu] = useState(null);
  const [showEmojiPicker, setShowEmojiPicker] = useState(null);

  const [showReactionsPopup, setShowReactionsPopup] = useState(false);
  const [selectedEmoji, setSelectedEmoji] = useState("All");
  const [reactionsData, setReactionsData] = useState([]);
  const [currentPopupChatId, setCurrentPopupChatId] = useState(null);

  const [isQuestionPopupVisible, setIsQuestionPopupVisible] = useState(false);

  const [pinnedPrimary, setPinnedPrimary] = useState(null);
  const [primaryMessages, setPrimaryMessages] = useState([]);
  const [allQuestions, setAllQuestions] = useState([]);
  const [isDetailsVisible, setDetailsVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const questionsPerPage = 1;

  const [multipleImage, setMultipleImage] = useState([]);
  const [multipleImageModel, setMultipleImageModel] = useState(false);
  const [selectedImageEdit, setSelectedImageEdiit] = useState(null);
  const [selectedMultiImage, setSelectedMultiImage] = useState(null);

  const [videoCrop, setVideoCrop] = useState({
    x: 0,
    y: 0,
    width: 200,
    height: 200,
  });
  const videoContainerRef = useRef(null);
  const videoCropRef = useRef(null);
  const [videoCropProcessing, setVideoCropProcessing] = useState(false);
  const [originalDimensions, setOriginalDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [displayDimensions, setDisplayDimensions] = useState({
    width: 0,
    height: 0,
    offsetLeft: 0,
    offsetTop: 0,
  });

  const [cropVideoOn, setCropVideoOn] = useState(false);
  // const [synopsisData, setSynopsisData] = useState(null);
  // const [isSynopsisVisible, setIsSynopsisVisible] = useState(false);
  const [showPrivacyOptions, setShowPrivacyOptions] = useState(false);
  const [recordingTime, setRecordingTime] = useState(0); // Elapsed time in seconds
  const [timerInterval, setTimerInterval] = useState(null); // To store the interval ID

  const privacyControlRef = useRef(null);

  //   console.log("showPrivacyOptions", showPrivacyOptions);

  const togglePrivacyOptions = (e) => {
    e.preventDefault();
    setShowPrivacyOptions((prev) => !prev);
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        privacyControlRef.current &&
        !privacyControlRef.current.contains(event.target)
      ) {
        setShowPrivacyOptions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setShowPrivacyOptions(false);
  }, [props.activeChatRoom, chatHistory]);

  const pinnedMessageRef = useRef({});

  const [replyingMessage, setReplyingMessage] = useState(null);

  const [permissionGranted, setPermissionGranted] = useState(false);
  const [recording, setRecording] = useState(false);
  const [recordedVideoURL, setRecordedVideoURL] = useState(null);
  const videoRecordRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const recordedChunksRef = useRef([]);

  const [isOpenQuestionAccord, setIsOpenQuestionAccord] = useState(false);
  const inputRef = useRef(null);
  const handleReplyClick = (message) => {
    // console.log("clicked reply message:", message);
    setReplyingMessage(message);
    inputRef.current?.focus();
    toggleMessageMenu(message.chatId);
  };

  const cancelReply = () => {
    setReplyingMessage(null);
  };

  // console.log("this is a primary message:", primaryMessages);
  // console.log("this is a pinned primary message:", pinnedPrimary);

  const [questionDetails, setQuestionDetails] = useState({
    questionText: "",
    questionImage: null,
    questionImagePreview: null,
    isPrimary: false,
  });

  const toggleQuestionAccordion = () => {
    // setIsOpenQuestionAccord(!isOpenQuestionAccord);
    setIsSearchOverlayOpen(false);
    setIsSearchActive(false);
    setDetailsVisible((prev) => !prev);
  };
  const toggleQuestionPopupVisibility = () => {
    setIsQuestionPopupVisible(!isQuestionPopupVisible);
    setAnswerPrivacy(false);
    setQuestionDetails({
      questionText: "",
      questionImage: null,
      isPrimary: false,
    });
  };

  const handleQuestionInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setQuestionDetails({
      ...questionDetails,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleQuestionImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageURL = URL.createObjectURL(file);
      setQuestionDetails({
        ...questionDetails,
        // Store the actual file object
        questionImage: file,
        // Store the preview URL
        questionImagePreview: imageURL,
      });
    }
  };

  const handleImageRemove = () => {
    setQuestionDetails({
      ...questionDetails,
      questionImage: null,
      questionImagePreview: null,
    });
  };

  const handleQuestionSubmit = async () => {
    if (!questionDetails.questionText.trim()) {
      alert("Question text is required!");
      return;
    }

    // Check if there is an image to upload
    let uploadedImageURL = "";
    if (questionDetails.questionImage) {
      try {
        const fd = new FormData();
        const uniqueName = `image_${Date.now()}_${Math.floor(
          Math.random() * 1000000
        )}.jpg`;
        fd.append("uploadFile", questionDetails.questionImage, uniqueName);
        fd.append("location", "questions");

        const response = await axios({
          url: "uploadfile",
          method: "post",
          data: fd,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
          },
        });

        if (response.data.data && response.data.data[0]) {
          uploadedImageURL = response.data.data[0];
        } else {
          alert("Failed to upload the image!");
          return;
        }
      } catch (error) {
        console.error("Image upload failed:", error);
        alert("Image upload failed!");
        return;
      }
    }

    // Construct the question data object
    const questionData = {
      message: questionDetails.questionText.trim(),
      // options: ["Option 1", "Option 2"],
      images: uploadedImageURL ? [uploadedImageURL] : [],
      isPrimary: questionDetails.isPrimary,
    };

    console.log("questionData:", questionData);

    // Construct the WebSocket payload
    const messagePayload = {
      userName: profile?.username || "",
      firstName: profile?.firstname || "",
      lastName: profile?.lastname || "",
      MessageType: "question",
      question: questionData,
      userImage: profile?.userImage || "",
      //   isPrivate: answerPrivacy,
    };

    console.log("Question Message Payload:", messagePayload);

    if (profile?.id) {
      sendMessage(messagePayload);
      setIsScrollToBottom(true);
      toggleQuestionPopupVisibility();
      setQuestionDetails({
        questionText: "",
        questionImage: null,
        isPrimary: false,
      });
    }
  };

  useEffect(() => {
    const chatRoomHistory = chatHistory?.[props?.activeChatRoom] || [];

    // Filter for primary questions
    const primaryList = chatRoomHistory
      .filter(
        (item) => item.messageType === "question" && item?.question?.isPrimary
      )
      .map((item) => ({
        ...item,
        displayMessage: item?.question?.message || item?.message || "",
      }));

    setPrimaryMessages(primaryList);

    if (primaryList.length > 0) {
      // Pin the latest primary question
      const latestPrimary = primaryList.reduce((latest, current) =>
        new Date(current?.createdOn) > new Date(latest?.createdOn)
          ? current
          : latest
      );
      setPinnedPrimary(latestPrimary);
    } else {
      // No primary questions, pin the latest non-primary question
      const latestNonPrimary = chatRoomHistory
        .filter(
          (item) =>
            item.messageType === "question" && !item?.question?.isPrimary
        ) // Non-primary questions
        .reduce(
          (latest, current) =>
            !latest ||
            new Date(current?.createdOn) > new Date(latest?.createdOn)
              ? current
              : latest,
          null
        );

      if (latestNonPrimary) {
        setPinnedPrimary({
          ...latestNonPrimary,
          displayMessage:
            latestNonPrimary?.question?.message ||
            latestNonPrimary?.message ||
            "",
        });
      } else {
        setPinnedPrimary(null); // No questions to pin
      }
    }
  }, [chatHistory, props.activeChatRoom]);

  useEffect(() => {
    const chatRoomHistory = chatHistory?.[props?.activeChatRoom] || [];

    // Sort and filter primary messages
    const sortedPrimaryMessages = primaryMessages
      .slice()
      .sort((a, b) => new Date(b?.createdOn) - new Date(a?.createdOn));

    // Get the latest 5 primary questions
    const latestPrimaryMessages = sortedPrimaryMessages.slice(0, 5);

    // Fill remaining slots with non-primary questions
    let additionalMessages = [];
    if (latestPrimaryMessages.length < 5) {
      const remainingCount = 5 - latestPrimaryMessages.length;
      additionalMessages = chatRoomHistory
        .filter(
          (item) =>
            item.messageType === "question" && !item?.question?.isPrimary
        ) // Non-primary questions
        .sort((a, b) => new Date(b?.createdOn) - new Date(a?.createdOn))
        .slice(0, remainingCount)
        .map((item) => ({
          ...item,
          displayMessage: item?.question?.message || item?.message || "",
        }));
    }

    // Combine primary and additional non-primary messages
    setAllQuestions([...latestPrimaryMessages, ...additionalMessages]);

    if (primaryMessages.length === 0) {
      // No primary messages, fill with top 5 questions of any type
      const latestQuestions = chatRoomHistory
        .filter((item) => item.messageType === "question") // Include all questions
        .sort((a, b) => new Date(b?.createdOn) - new Date(a?.createdOn))
        .slice(0, 5)
        .map((item) => ({
          ...item,
          displayMessage: item?.question?.message || item?.message || "",
        }));

      setAllQuestions(latestQuestions);
    }
  }, [primaryMessages, chatHistory, props.activeChatRoom]);

  useEffect(() => {
    const newPrimary = chatHistory?.[props?.activeChatRoom]?.find(
      (msg) =>
        msg.messageType === "question" &&
        msg?.question?.isPrimary &&
        new Date(msg?.createdOn) > new Date(pinnedPrimary?.createdOn || 0)
    );

    if (newPrimary) {
      setPinnedPrimary({
        ...newPrimary,
        displayMessage:
          newPrimary?.question?.message || newPrimary?.message || "",
      });
    }
  }, [chatHistory, props.activeChatRoom, pinnedPrimary]);

  // Calculate current questions for pagination
  const indexOfLastQuestion = currentPage * questionsPerPage;
  const indexOfFirstQuestion = indexOfLastQuestion - questionsPerPage;
  const currentQuestions = allQuestions.slice(
    indexOfFirstQuestion,
    indexOfLastQuestion
  );

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPagination = () => {
    const pageNumbers = [];
    for (
      let i = 1;
      i <= Math.ceil(allQuestions.length / questionsPerPage);
      i++
    ) {
      pageNumbers.push(
        <p
          key={i}
          onClick={() => handlePageChange(i)}
          className={`pagination-item ${
            currentPage === i ? "pagination-item--active" : ""
          }`}
        >
          {i}
        </p>
      );
    }
    return <div className="pagination-container">{pageNumbers}</div>;
  };
  // console.log("primary Questions", primaryMessages);
  // console.log("all Questions", allQuestions);
  // console.log("currentQuestions", currentQuestions);

  useEffect(() => {
    // Reset the details visibility and remove the 'expanded' class when activeCase changes
    setDetailsVisible(false);
    const container = document.querySelector(".pinned-primary-container");
    if (container && container.classList.contains("expanded")) {
      container.classList.remove("expanded");
    }
  }, [chatHistory, props.activeChatRoom]);

  const handleScrollToPinnedMessage = () => {
    const messageElement = pinnedMessageRef.current?.[pinnedPrimary.chatId];
    if (messageElement) {
      messageElement.scrollIntoView({ behavior: "smooth", block: "center" });
    } else {
      console.error("Pinned message element not found:", pinnedPrimary.chatId);
    }
  };

  const toggleDetailsVisibility = () => {
    setDetailsVisible((prev) => !prev);
    const container = pinnedMessageRef.current;
    if (container) container.classList.toggle("expanded");
  };

  const fetchSynopsis = async (roomId) => {
    setIsSynopsisVisible(true);
    try {
      const response = await axios({
        url: `get/synopsis/${roomId}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwtTokenApi1")}`,
        },
      });

      if (response) {
        setSynopsisData(response.data.synopsis);
        setIsSynopsisVisible(true);
      } else {
        Swal.fire({
          title: "No Synopsis Found",
          text: "This room does not have a synopsis available.",
          icon: "info",
        });
      }
    } catch (error) {
      console.error("Error fetching synopsis:", error);
      Swal.fire({
        title: "Error",
        text: "Failed to fetch synopsis data.",
        icon: "error",
      });
    }
  };

  useEffect(() => {
    setIsSynopsisVisible(false);
    setSynopsisData(null);
  }, [props?.activeChatRoom]);
  useEffect(() => {
    setAnswerPrivacy(false);
  }, [props?.activeChatRoom, chatHistory]);

  const openReactionsPopup = (chatId, reactions) => {
    if (currentPopupChatId === chatId && showReactionsPopup) {
      closeReactionsPopup();
    } else {
      if (Array.isArray(reactions)) {
        setReactionsData(reactions);
      } else {
        console.error("Reactions data is not an array:", reactions);
        setReactionsData([]); // Fallback to an empty array
      }
      setSelectedEmoji("All"); // Show all reactions by default
      setCurrentPopupChatId(chatId); // Track the chatId
      setShowReactionsPopup(true);
    }
  };

  const closeReactionsPopup = () => {
    setShowReactionsPopup(false);
    setCurrentPopupChatId(null); // Reset chatId when closing
  };

  const uniqueEmojis = [
    ...new Set(reactionsData?.map((reaction) => reaction.emoji)),
  ];

  const filteredReactions = selectedEmoji
    ? reactionsData.filter((reaction) => reaction.emoji === selectedEmoji)
    : reactionsData;

  // Toggle message menu

  const toggleMessageMenu = (chatId) => {
    setShowMessageMenu((prev) => (prev === chatId ? null : chatId));
    setShowEmojiPicker(null);
  };

  const [emojiPickerPosition, setEmojiPickerPosition] = useState(
    "emoji-picker-bottom"
  );

  const calculatePickerPosition = (triggerElement) => {
    const pickerHeight = 400; // Approximate height of the picker
    const buffer = 20; // Padding
    const viewportHeight = window.innerHeight;
    const { top, bottom } = triggerElement.getBoundingClientRect();

    if (viewportHeight - bottom > pickerHeight + buffer) {
      setEmojiPickerPosition("emoji-picker-bottom");
    } else if (top > pickerHeight + buffer) {
      setEmojiPickerPosition("emoji-picker-top");
    } else {
      setEmojiPickerPosition("emoji-picker-bottom"); // Default fallback
    }
  };

  // const toggleEmojiPicker = (chatId) => {
  //   const triggerElement = messageMenuRef.current;
  //   if (triggerElement) {
  //     calculatePickerPosition(triggerElement);
  //   }
  //   setShowEmojiPicker((prev) => (prev === chatId ? null : chatId));
  // };

  // Toggle emoji picker
  //   const toggleEmojiPicker = (chatId) => {
  //     setShowEmojiPicker((prev) => (prev === chatId ? null : chatId));
  //     setShowMessageMenu(null);
  //   };

  const toggleEmojiPicker = (chatId, triggerElement) => {
    setShowEmojiPicker((prev) => (prev === chatId ? null : chatId));
    setShowMessageMenu(null);

    if (triggerElement) {
      const pickerHeight = 300; // Height of the emoji picker
      const buffer = 20; // Padding buffer
      const viewportHeight = window.innerHeight;
      const { top, bottom } = triggerElement.getBoundingClientRect();

      let positionClass = "";

      // Determine top or bottom placement dynamically
      if (viewportHeight - bottom > pickerHeight + buffer) {
        positionClass = "bottom"; // Enough space below
      } else if (top > pickerHeight + buffer) {
        positionClass = "top"; // Enough space above
      } else {
        positionClass = "bottom"; // Default fallback
      }

      setEmojiPickerPosition(positionClass); // Set the position class (top/bottom)
    }
  };

  // Handle emoji selection
  const handleEmojiSelect = (item, emojiObject, event) => {
    const userProfilePic = userStore?.profile?.userImage
      ? userStore?.profile?.userImage
      : "";

    sendReactionToMessage(
      String(item?.chatId),
      event.emoji,
      userStore.profile.username,
      userProfilePic
    );
    setIsScrollToBottom(false);
    setShowEmojiPicker(null);
  };

  const messageMenuRef = useRef(null);

  // Close menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        messageMenuRef.current &&
        !messageMenuRef.current.contains(event.target)
      ) {
        setShowMessageMenu(null);
        setShowEmojiPicker(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const chatMessageRefs = useRef({});

  // Update `messageRefs` when `chatHistory` changes
  useEffect(() => {
    // closeAllVoters();
    const refs = {};
    chatHistory?.[props?.activeChatRoom]?.forEach((msg) => {
      refs[msg.chatId] = React.createRef();
    });
    messageRefs.current = refs;
  }, [chatHistory, props.activeChatRoom]);

  useEffect(() => {
    if (searchTerm) {
      const lowerSearchTerm = searchTerm.toLowerCase();
      const filtered = chatHistory[props?.activeChatRoom]?.filter((msg) =>
        msg.message.toLowerCase().includes(lowerSearchTerm)
      );
      setFilteredMessages(filtered);
    } else {
      setFilteredMessages([]);
    }
  }, [searchTerm, chatHistory]);

  const highlightText = (text, searchTerm) => {
    if (!searchTerm) return text;

    let highlighted = false; // Flag to check if the term has been highlighted
    const parts = text.split(new RegExp(`(${searchTerm})`, "gi"));

    return parts.map((part, index) => {
      if (!highlighted && part.toLowerCase() === searchTerm.toLowerCase()) {
        highlighted = true; // Mark that we’ve highlighted once
        return (
          <span key={index} className="highlight">
            {part}
          </span>
        );
      }
      return part;
    });
  };

  const handleMessageClick = (msgId) => {
    const messageRef = chatMessageRefs.current[msgId];
    setHighlightedMessageId(msgId);
    setIsSearchActive(false);
    toggleSearchOverlay();
    if (messageRef && messageRef.current) {
      messageRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }

    // Clear highlight after 3 seconds
    setTimeout(() => setHighlightedMessageId(null), 3000);
  };

  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
  };

  const handleSaveEditedImage = (editedImageURL) => {
    console.log("Edited the image ");
    // console.log("Edited image URL:", editedImageURL);
    // setChatImageEditeResult(editedImageURL)
  };

  const toggleViewMoreMenu = () => {
    console.log("clicked more menu");
    setIsViewMoreMenuOpen((prev) => !prev);
  };

  const closeMenu = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsViewMoreMenuOpen(false);
      setIsSearchActive(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", closeMenu);
    return () => {
      document.removeEventListener("click", closeMenu);
    };
  }, []);

  const toggleSearchMessagesByUserPopup = () => {
    setIsSearchMessageByUsernamePopop((prev) => !prev);
  };

  useEffect(() => {
    if (props.activeChatRoom) {
      connectToWebSocket(props.activeChatRoom);
    }

    // Cleanup on component unmount
    return () => {
      closeWebSocket();
    };
  }, [props.activeChatRoom]);

  // console.log("active chat room:", activeChatGroupData);

  const unFreezeRoom = async (id) => {
    const response = await axios({
      url: `chat-group/${id}/freeze`,
      method: "put",
      withCredentials: false,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
      },
    });
    // console.log("Room is successfully unfreezed:", response);

    // let chatgroups = response.data.data;
    let chatStore = { ...store.chatStore };
    let responseData = { ...response.data };

    // Find and update the group by ID
    let groups = chatStore.groups.map((group) => {
      if (group.id === responseData.groupId) {
        // Update only the freeze status
        return { ...group, freeze: responseData.newFreezeStatus };
      }
      // Keep other groups unchanged
      return group;
    });

    // Update the store with the modified groups
    setStore({
      ...store,
      chatStore: { ...chatStore, groups },
    });

    // console.log("Updated chat groups:", groups);
    if (response) {
      Swal.fire({
        title: "Success!",
        text: "Room is successfully unfreezed",
        icon: "success",
      });
    }
  };

  useEffect(() => {
    if (props.activeChatRoom) {
      const activeChatRoom = chatStore?.groups?.find(
        (group) => group.id === props.activeChatRoom
      );
      setActiveChatGroupData(activeChatRoom);
    }
  }, [props.activeChatRoom, unFreezeRoom]);

  // console.log("activeChatGroupData", activeChatGroupData);

  // Toggle popup visibility
  const togglePollPopup = () => {
    setAnswerPrivacy(false);
    setIsPopupOpen(!isPopupOpen);
    resetPollForm();
  };

  // Reset form inputs
  const resetPollForm = () => {
    setQuestion("");
    setOptions(["", ""]);
    setDuplicateError(null);
  };

  let draggedItemIndex = null;

  const handleDragStart = (e, index) => {
    // Save the index of the item being dragged
    draggedItemIndex = index;
  };

  const handleDrop = (e, dropIndex) => {
    // Rearrange the options array
    const updatedOptions = [...options];
    // Remove dragged item
    const [draggedItem] = updatedOptions.splice(draggedItemIndex, 1);
    // Insert it at the new position
    updatedOptions.splice(dropIndex, 0, draggedItem);

    setOptions(updatedOptions);
  };

  const handleAddOption = () => {
    if (options.length < 4) {
      setOptions([...options, ""]);
    }
  };

  //   console.log('Initial options:', options);
  //   console.log("question", question)
  //   console.log("question", question)
  const handleDeleteOption = (index) => {
    const updatedOptions = options.filter((_, idx) => idx !== index);
    setOptions(updatedOptions);
  };

  const handleQuestionChange = (event) => {
    setQuestion(event.target.value);
  };

  const handleOptionChange = (index, value) => {
    const updatedOptions = [...options];
    updatedOptions[index] = value;

    const isDuplicate = updatedOptions.some(
      (opt, idx) => opt.trim() === value.trim() && idx !== index
    );

    if (isDuplicate) {
      setDuplicateError(index);
    } else {
      setDuplicateError(null);
    }

    setOptions(updatedOptions);
  };

  const handlePollCreationSubmit = () => {
    if (!question.trim()) {
      alert("Poll question is required!");
      return;
    }

    const filledOptions = options.filter((opt) => opt.trim());
    if (filledOptions.length < 2) {
      alert("At least two options are required!");
      return;
    }

    // Construct poll data object
    const pollData = {
      pollText: question,
      pollOptions: filledOptions.map((option) => ({ text: option.trim() })),
    };

    const message = {
      userID: profile?.id || 0,
      groupID: props.activeChatRoom,
      userName: profile?.username,
      MessageType: "poll",
      Poll: pollData,
      userImage: profile?.userImage || "",
      //   isPrivate: answerPrivacy,
    };

    console.log("Poll Message:", message);

    if (profile?.id) {
      sendMessage(message);
      setIsScrollToBottom(true);
      setChatMessage("");
      setTextareaHeight(50);
      togglePollPopup();
      setQuestion("");
      setOptions(["", "", ""]);
    }
  };

  useEffect(() => {
    if (chatImageEditURL) {
      console.log("Selected image URL for editing:", chatImageEditURL);
    }
  }, [chatImageEditURL]);

  const searchRef = useRef(null);
  const overlayRef = useRef(null);

  const handleSearchClick = () => {
    setIsSearchActive((prev) => !prev);
    toggleSearchOverlay();
  };

  // Close menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsViewMoreMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (
      searchRef.current &&
      !searchRef.current.contains(event.target) &&
      overlayRef.current &&
      !overlayRef.current.contains(event.target) &&
      menuRef.current &&
      !menuRef.current.contains(event.target)
    ) {
      setIsSearchActive(false);
      setIsSearchOverlayOpen(false);
      setIsViewMoreMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  // const handleGroupInfoClick = () => {
  //   toggleGroupInfoOverlay();
  // };

  //   const handleSearchClick = () => {
  //     setIsSearchActive(true);
  //   };

  const handleInputBlur = () => {
    setIsSearchActive(false);
  };

  const textareaStyle = {
    height: `${textareaHeight}px`,
    minHeight: "50px",
    maxHeight: "100px",
  };

  // const { getRootProps, getInputProps, isDragActive } = useDropzone({
  //   noClick: true, // Prevents click-to-open file dialog
  //   onDrop: async (acceptedFiles) => {
  //     acceptedFiles.forEach(async (file) => {
  //       const fileType = file.type;

  //       if (fileType.startsWith("image/") || fileType.startsWith("video/")) {
  //         // Create a fake event object for images/videos
  //         const event = {
  //           preventDefault: () => {},
  //           target: {
  //             files: [file], // Wrap file in an array
  //           },
  //         };
  //         await sendAttachImageHandle(event); // Call the image/video handler
  //       } else if (
  //         fileType === "application/pdf" ||
  //         fileType === "application/msword" || // For .doc files
  //         fileType ===
  //           "application/vnd.openxmlformats-officedocument.wordprocessingml.document" // For .docx files
  //       ) {
  //         // Create a fake event object for documents
  //         const event = {
  //           target: {
  //             files: [file], // Wrap file in an array
  //           },
  //         };
  //         sendAttachDocsHandle(event); // Call the document handler
  //       }
  //     });
  //   },
  // });

  // const { getRootProps, getInputProps, isDragActive } = useDropzone({
  //   noClick: true, // Prevents click-to-open file dialog
  //   multiple: true, // Allows multiple files to be dragged
  //   onDrop: async (acceptedFiles) => {
  //     if (acceptedFiles && acceptedFiles.length > 0) {
  //       // Create a mock event object for `sendAttachImageHandle`
  //       const event = {
  //         target: {
  //           files: acceptedFiles, // Pass the dragged files
  //         },
  //         preventDefault: () => {}, // Prevent default behavior
  //       };

  //       // Call the existing function with the mock event
  //       await sendAttachImageHandle(event);
  //     }
  //   },
  // });

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    noClick: true, // Prevents click-to-open file dialog
    multiple: true, // Allows multiple files to be dragged and dropped
    onDrop: async (acceptedFiles) => {
      const imageFiles = [];
      const otherFiles = [];

      acceptedFiles.forEach((file) => {
        const fileType = file.type;

        if (fileType.startsWith("image/")) {
          imageFiles.push(file); // Collect image files
        } else {
          otherFiles.push(file); // Collect other types (videos, docs, etc.)
        }
      });

      // Process images in bulk for drag-and-drop
      if (imageFiles.length > 0) {
        const event = {
          preventDefault: () => {},
          target: {
            files: imageFiles, // Pass all image files in a single event
          },
        };
        await sendAttachImageHandle(event); // Call the handler for images
      }

      // Process videos and other files individually
      otherFiles.forEach(async (file) => {
        const fileType = file.type;

        if (fileType.startsWith("video/")) {
          const event = {
            preventDefault: () => {},
            target: {
              files: [file], // Wrap video file in an array
            },
          };
          await sendAttachImageHandle(event); // Call the handler for videos
        } else if (
          fileType === "application/pdf" ||
          fileType === "application/msword" || // For .doc files
          fileType ===
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document" // For .docx files
        ) {
          const event = {
            target: {
              files: [file], // Wrap document file in an array
            },
          };
          sendAttachDocsHandle(event); // Call the handler for documents
        }
      });
    },
  });

  // useEffect(() => {
  //   let isPreviousDiabled = carouselActiveIndex <= 0 ? true : false;
  //   const allImages =
  //     chatHistory[props.activeChatRoom]
  //       ?.filter((i) => i.images !== "")
  //       .map((e) => e.images) || [];
  //   let isNextDiabled =
  //     carouselActiveIndex >= allImages.length - 1 ? true : false;

  //   setPreButtonDisable(isPreviousDiabled);
  //   setNextButtonDisable(isNextDiabled);
  // }, [carouselActiveIndex]);

  useEffect(() => {
    const allItems =
      chatHistory[props.activeChatRoom]?.filter(
        (i) => i.images !== "" || i.videos !== ""
      ) || [];

    const isPreviousDisabled = carouselActiveIndex <= 0;
    const isNextDisabled = carouselActiveIndex >= allItems.length - 1;

    setPreButtonDisable(isPreviousDisabled);
    setNextButtonDisable(isNextDisabled);
  }, [carouselActiveIndex]);

  const scrollTarget = useRef(null);
  // const videoRef = useRef();
  /**
   * Socket connection and reconnection logic handeled here
   */

  const updateChatTimes = (arr) => {
    if (arr.length > 0) {
      arr.map((item) => {
        const now = moment(new Date());
        const end = moment(item.createdOn);
        const duration = moment.duration(now.diff(end));
        const seconds = duration.asSeconds();

        let secondsValue = Math.floor(seconds);
        item["displayTime"] =
          secondsValue > 1 ? secondsValue + " second ago" : "Second ago";

        // minutes
        if (seconds > 60) {
          let minutesValue = Math.floor(seconds / 60);
          item["displayTime"] =
            minutesValue > 1 ? minutesValue + " minutes ago" : "Minute ago";
        }

        // hours
        if (seconds > 60 * 60) {
          let hoursValue = Math.floor(seconds / (60 * 60));
          item["displayTime"] =
            hoursValue > 1 ? hoursValue + " hours ago" : "An hour ago";
        }

        // days
        if (seconds > 60 * 60 * 24) {
          let dayValue = Math.floor(seconds / (60 * 60 * 24));
          item["displayTime"] =
            dayValue > 1 ? dayValue + " days ago" : "Day ago";
        }
        return item;
      });
    }
    return arr;
  };

  const addAttachHandle = () => {
    setAddAttachActive(!addAttachActive);
  };

  if (addAttachActive === true) {
    const concernedElement = document.querySelector(
      ".ir-ws-add-attachment-main-container"
    );
    document.addEventListener("mousedown", (event) => {
      if (!concernedElement.contains(event.target)) {
        setAddAttachActive(false);
      }
    });
  }

  const scrollToBottom = () => {
    setTimeout(() => {
      scrollTarget?.current?.scrollIntoView({ behavior: "instant" });
    }, 400);
  };

  const showShareModal = () => {
    setShareModal(true);
  };
  const shareCloseModalHandle = () => {
    setShareModal(false);
  };

  //   console.log("replyingMessage object", replyingMessage);

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Build the chat message payload
    const processedChatMessage = chatMessage.replace(/'/g, "''");

    const message = {
      userID: profile?.id || 0,
      message: processedChatMessage,
      groupID: props.activeChatRoom,
      userName: profile?.username,
      MessageType: "message",
      userImage: profile?.userImage || "",
      isPrivate: answerPrivacy,
      isReply: !!replyingMessage, // Indicate if this is a reply
      replyTo: replyingMessage
        ? {
            chatId: replyingMessage.chatId || 0,
            userName: replyingMessage.userName || "",
            userImage: replyingMessage.userImage || "",
            message: replyingMessage.message || "",
            messageType: replyingMessage.messageType || "message",
            imageUrl: replyingMessage.images || "",
            videoUrl: replyingMessage.videos || "",
            videoThumbnail: replyingMessage.videoThumbnail || "",
            audioUrl: replyingMessage.audio || "",
            documentUrl: replyingMessage.documents || "",
          }
        : null,
    };

    console.log("Message is being sent", message);

    // Send the message if the user ID exists
    if (profile?.id) {
      sendMessage(message);
      togglePrivacyOptions(event);
      setIsScrollToBottom(true);
      setChatMessage("");
      setTextareaHeight(50);
      setReplyingMessage(null); // Clear the reply state after sending
    }
  };

  const handleChatSendMessage = (event) => {
    const newChatMessage = event.target.value;
    setChatMessage(newChatMessage);
    if (newChatMessage === "") {
      // Reset textarea height to the default (50px) when content is empty
      setTextareaHeight(50);
    } else {
      // Calculate and update the textarea's height
      const newHeight = Math.min(100, Math.max(50, event.target.scrollHeight));
      setTextareaHeight(newHeight);
    }
    // const newHeight = Math.min(100, Math.max(50, event.target.scrollHeight));
    // setTextareaHeight(newHeight);
  };

  const handleEnterKeyPress = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      // Prevent the default Enter key behavior (usually creating a new line)
      event.preventDefault();
      // Trigger form submission
      handleSubmit(event);
    }
  };

  //   const voiceCloseModalHandle = () => {
  //     setOpenVoiceModal(false);
  //   };

  const openCanvasModal = () => {
    setAddAttachActive(false);
    setOpenWhiteBoardModal(true);
  };

  const whiteBoardCloseModalHandle = () => {
    setOpenWhiteBoardModal(false);
  };

  const imageCloseModalHandle = () => {
    setOpenImageModal(false);
    setConvertedVideoURL(null);
    setProgress(0);
    setSelectedImage([] || {});
    setTrimRange([0, 0]);
    setIsTrimming(false);
    setIsTrimmedVideoConverted(true);
    setClinicalNote("");
    setOpenVideoModal(false);
    setShowSavedEditedImage(false);
    setSavedEditedImage(null);
    setSelectedMultiImage(null);
    setMultipleImageModel(false);
  };

  const attachDocsCloseModalHandle = () => {
    setOpenAttachDocsModal(false);
  };

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  // Helper function to capture the first frame of a video

  const captureVideoThumbnail = async (videoFile, captureTime = 1) => {
    return new Promise((resolve, reject) => {
      const videoElement = document.createElement("video");
      const canvas = document.createElement("canvas");

      videoElement.src = URL.createObjectURL(videoFile);
      videoElement.muted = true;

      videoElement.onloadeddata = () => {
        // Seek to the desired time
        videoElement.currentTime = captureTime;

        videoElement.ontimeupdate = () => {
          if (videoElement.currentTime >= captureTime) {
            // Set canvas dimensions to match video frame size
            canvas.width = videoElement.videoWidth;
            canvas.height = videoElement.videoHeight;

            // Draw the frame onto the canvas
            const ctx = canvas.getContext("2d");
            ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

            // Convert the canvas to a Blob
            canvas.toBlob(
              (blob) => {
                if (blob) {
                  const thumbnailURL = URL.createObjectURL(blob); // Create a URL for the blob
                  console.log("Thumbnail URL:", thumbnailURL); // Log the URL
                  resolve(thumbnailURL); // Resolve with the URL
                } else {
                  reject(new Error("Failed to generate thumbnail blob."));
                }
              },
              "image/jpeg",
              0.9 // Adjust quality if needed
            );

            // Stop further updates
            videoElement.ontimeupdate = null;
          }
        };
      };

      videoElement.onerror = (error) => {
        reject(
          new Error("Error loading video for thumbnail extraction: " + error)
        );
      };

      videoElement.load();
    });
  };

  // const sendAttachImageHandle = async (event) => {
  //   try {
  //     setAddAttachActive(false);
  //     if (event.target.files && event.target.files.length > 0) {
  //       setSelectedImage(event.target.files);
  //       setUploaded(null);

  //       // Desired bitrate threshold in kbps
  //       const BITRATE_THRESHOLD = 500;

  //       for (let i = 0; i < event.target.files.length; i++) {
  //         const file = event.target.files[i];

  //         // Check if file is a video
  //         if (file.type.startsWith("video/")) {
  //           setOpenVideoModal(true);
  //           setProgress(1);

  //           // Create a video element to load metadata
  //           const videoElement = document.createElement("video");
  //           videoElement.src = URL.createObjectURL(file);

  //           let metadataLoaded = false;

  //           videoElement.onloadedmetadata = async () => {
  //             metadataLoaded = true; // Set flag when metadata is loaded
  //             const durationInSeconds = videoElement.duration;
  //             let bitrateKbps = null;

  //             if (durationInSeconds && file.size) {
  //               // Calculate bitrate if metadata is available
  //               const fileSizeInBits = file.size * 8;
  //               bitrateKbps = (
  //                 fileSizeInBits /
  //                 durationInSeconds /
  //                 1000
  //               ).toFixed(2);
  //               console.log("Extracted bitrate:", bitrateKbps, "kbps");
  //             } else {
  //               console.log("Bitrate or duration metadata is missing.");
  //             }

  //             // Convert if bitrate is low, missing, or below threshold
  //             if (!bitrateKbps || parseFloat(bitrateKbps) < BITRATE_THRESHOLD) {
  //               await convertVideo(file);
  //             } else {
  //               // Capture thumbnail before proceeding
  //               try {
  //                 const videoThumbnail = await captureVideoThumbnail(file, 1);
  //                 setVideoThumbnail(videoThumbnail);
  //                 console.log("Video thumbnail URL at 1 sec:", videoThumbnail);
  //               } catch (error) {
  //                 console.error("Error in capturing video thumbnail:", error);
  //               }

  //               // Skip conversion if bitrate is sufficient
  //               console.log("Bitrate is sufficient; skipping conversion.");
  //               setConvertedVideoURL(URL.createObjectURL(file));
  //               setIsTrimming(true);
  //               setDisableUploadBtn(false);
  //               setProgress(100);
  //             }
  //           };

  //           // Fallback for videos with no metadata (after 2 seconds)
  //           setTimeout(async () => {
  //             if (!metadataLoaded) {
  //               console.log(
  //                 "Metadata not available, converting video by default."
  //               );
  //               await convertVideo(file);
  //             }
  //           }, 2000);

  //           // Trigger onloadedmetadata event
  //           videoElement.load();
  //           return; // Stop processing after the first video
  //         }
  //       }

  //       // If it's an image, open the modal directly
  //       setOpenImageModal(true);

  //       setDisableUploadBtn(false);
  //     }
  //   } catch (error) {
  //     console.error("Error uploading video or image:", error);
  //   }
  // };

  const sendAttachImageHandle = async (event) => {
    try {
      setAddAttachActive(false);
      if (event.target.files && event.target.files.length > 0) {
        setSelectedImage(event.target.files);
        setUploaded(null);

        // Desired bitrate threshold in kbps
        const BITRATE_THRESHOLD = 500;

        const imagesArray = []; // Array to store image objects with id and imageFile

        let imageId = 1; // Start the id counter from 1

        for (let i = 0; i < event.target.files.length; i++) {
          const file = event.target.files[i];

          // Check if the file is a video
          if (file.type.startsWith("video/")) {
            setOpenVideoModal(true);
            setProgress(1);

            // Create a video element to load metadata
            const videoElement = document.createElement("video");
            videoElement.src = URL.createObjectURL(file);

            let metadataLoaded = false;

            videoElement.onloadedmetadata = async () => {
              metadataLoaded = true; // Set flag when metadata is loaded
              const durationInSeconds = videoElement.duration;
              let bitrateKbps = null;

              if (durationInSeconds && file.size) {
                // Calculate bitrate if metadata is available
                const fileSizeInBits = file.size * 8;
                bitrateKbps = (
                  fileSizeInBits /
                  durationInSeconds /
                  1000
                ).toFixed(2);
                console.log("Extracted bitrate:", bitrateKbps, "kbps");
              } else {
                console.log("Bitrate or duration metadata is missing.");
              }

              // Convert if bitrate is low, missing, or below threshold
              if (!bitrateKbps || parseFloat(bitrateKbps) < BITRATE_THRESHOLD) {
                await convertVideo(file);
              } else {
                // Capture thumbnail before proceeding
                try {
                  const videoThumbnail = await captureVideoThumbnail(file, 1);
                  setVideoThumbnail(videoThumbnail);
                  console.log("Video thumbnail URL at 1 sec:", videoThumbnail);
                } catch (error) {
                  console.error("Error in capturing video thumbnail:", error);
                }

                // Skip conversion if bitrate is sufficient
                console.log("Bitrate is sufficient; skipping conversion.");
                setConvertedVideoURL(URL.createObjectURL(file));
                setIsTrimming(true);
                setDisableUploadBtn(false);
                setProgress(100);
              }
            };

            // Fallback for videos with no metadata (after 2 seconds)
            setTimeout(async () => {
              if (!metadataLoaded) {
                console.log(
                  "Metadata not available, converting video by default."
                );
                await convertVideo(file);
              }
            }, 2000);

            // Trigger onloadedmetadata event
            videoElement.load();
            return; // Stop processing after the first video
          }

          // If it's an image, add it to the imagesObject with an id key
          if (file.type.startsWith("image/")) {
            imagesArray.push({
              id: imageId, // Unique id for each image
              imageFile: URL.createObjectURL(file), // The file object
              message: "",
            });
            imageId++; // Increment the id for the next image
          }
        }

        // If there are images, update the state with the imagesObject
        if (imagesArray.length > 0) {
          setMultipleImage(imagesArray); // Set the multipleImage state with the images array
          setOpenImageModal(true);
          setSelectedMultiImage(imagesArray[0]);
          // setMultipleImageModel(true);
        }

        setDisableUploadBtn(false);
      }
    } catch (error) {
      console.error("Error uploading video or image:", error);
    }
  };

  // const handleImageMessageChange = (e) => {
  //   const newMessage = e.target.value;
  // console.log(newMessage)
  //   setMultipleImage((prevImages) =>
  //     prevImages.map((image) =>
  //       image.id === selectedMultiImage.id
  //         ? { ...image, message: newMessage } // Update the message for the selected image
  //         : image
  //     )
  //   );
  // };

  const handleImageMessageChange = (e) => {
    const newMessage = e.target.value;
    console.log(newMessage);

    setMultipleImage((prevImages) =>
      prevImages.map((image) =>
        image.id === selectedMultiImage.id
          ? { ...image, message: newMessage } // Update the message for the selected image
          : image
      )
    );

    // Update selectedMultiImage to reflect the change in the input field
    setSelectedMultiImage((prevSelected) => ({
      ...prevSelected,
      message: newMessage,
    }));
  };

  const handleEditImage = (image) => {
    setMultipleImageModel(true);
    setSelectedImageEdiit(image);
  };
  // const deleteImage = (imageId) => {
  //   setMultipleImage((prevImages) => {
  //     // Filter out the image to be deleted
  //     const updatedImages = prevImages.filter((image) => image.id !== imageId);

  //     // Reassign IDs to make them consecutive
  //     const reassignedImages = updatedImages.map((image, index) => ({
  //       ...image,
  //       id: index + 1, // Reassign ID based on the new index
  //     }));

  //     // Check if no images are left after deletion
  //     if (updatedImages.length === 0) {
  //       imageCloseModalHandle();
  //     }

  //     return reassignedImages;
  //   });
  // };

  const deleteImage = (imageId) => {
    setMultipleImage((prevImages) => {
      // Filter out the image to be deleted
      const updatedImages = prevImages.filter((image) => image.id !== imageId);

      // Reassign IDs to make them consecutive
      const reassignedImages = updatedImages.map((image, index) => ({
        ...image,
        id: index + 1, // Reassign ID based on the new index
      }));

      // Update selectedMultiImage if the deleted image is the current selected one
      if (selectedMultiImage?.id === imageId) {
        if (reassignedImages.length > 0) {
          // Set the first image as the new selected image
          console.log(reassignedImages[0]);
          setSelectedMultiImage(reassignedImages[0]);
        } else {
          // If no images remain, clear the selected image
          setSelectedMultiImage(null);
        }
      }

      // Close the modal if no images are left
      if (reassignedImages.length === 0) {
        imageCloseModalHandle();
      }

      return reassignedImages;
    });
  };

  useEffect(() => {
    console.log(multipleImage, "Multiple image data");
    // console.log(selectedMultiImage, "Multiple image data");
    // console.log(selectedImageEdit, "Selected image object");
  }, [multipleImage, selectedImageEdit]);
  // Helper function for video conversion
  const convertVideo = async (file) => {
    console.log("Starting video conversion...");
    const ffmpeg = new FFmpeg();
    await ffmpeg.load();
    setProgress(25);

    await ffmpeg.writeFile("input.mp4", await fetchFile(file));
    await ffmpeg.exec([
      "-i",
      "input.mp4",
      "-b:v",
      "500k",
      "converted_output.mp4",
    ]);
    setProgress(50);

    const convertedData = await ffmpeg.readFile("converted_output.mp4");
    const convertedBlob = new Blob([convertedData.buffer], {
      type: "video/mp4",
    });
    const videoURL = URL.createObjectURL(convertedBlob);

    setConvertedVideoURL(videoURL);
    setIsTrimming(true);
    setDisableUploadBtn(false);
    setProgress(100);
    console.log("Video conversion completed.");

    // Capture thumbnail before proceeding
    try {
      const videoThumbnail = await captureVideoThumbnail(convertedBlob, 1);
      setVideoThumbnail(videoThumbnail);
      console.log("Video thumbnail URL at 1 sec:", videoThumbnail);
    } catch (error) {
      console.error("Error in capturing video thumbnail:", error);
    }
  };

  const trimVideo = async () => {
    try {
      console.log("Trimming and conversion process started.");

      setIsTrimmedVideoConverted(false);
      setIsTrimming(true);
      // const ffmpeg = new FFmpeg();
      const ffmpeg = new FFmpeg({
        corePath:
          "https://cdn.jsdelivr.net/npm/@ffmpeg/ffmpeg@0.12.13/dist/umd/ffmpeg.min.js", // Test with CDN
      });
      await ffmpeg.load();
      setProgress(1); // Start progress

      const file = selectedImage[0];
      const start = trimRange[0].toFixed(2);
      const end = trimRange[1].toFixed(2);

      // Step 1: Trim the video
      console.log(
        "Trimming video from",
        formatTime(start),
        "to",
        formatTime(end)
      );
      await ffmpeg.writeFile("input.mp4", await fetchFile(file));
      await ffmpeg.exec([
        "-i",
        "input.mp4",
        "-ss",
        start,
        "-to",
        end,
        "-c",
        "copy",
        "output_trimmed.mp4",
      ]);
      setProgress(50); // Progress halfway for trimming
      console.log("Trimming completed.");

      // Step 2: Check bitrate and convert if necessary
      console.log("Checking trimmed video's bitrate.");
      const trimmedData = await ffmpeg.readFile("output_trimmed.mp4");
      const trimmedBlob = new Blob([trimmedData.buffer], { type: "video/mp4" });
      const videoElement = document.createElement("video");
      videoElement.src = URL.createObjectURL(trimmedBlob);

      // Desired bitrate threshold in kbps
      const BITRATE_THRESHOLD = 500;
      let metadataLoaded = false;

      videoElement.onloadedmetadata = async () => {
        metadataLoaded = true;
        const durationInSeconds = videoElement.duration;
        let bitrateKbps = null;

        if (durationInSeconds && trimmedBlob.size) {
          const fileSizeInBits = trimmedBlob.size * 8;
          bitrateKbps = (fileSizeInBits / durationInSeconds / 1000).toFixed(2);
          console.log("Extracted bitrate:", bitrateKbps, "kbps");
        } else {
          console.log("Bitrate or duration metadata is missing.");
        }

        // Convert if bitrate is low or metadata is missing
        if (!bitrateKbps || parseFloat(bitrateKbps) < BITRATE_THRESHOLD) {
          await convertTrimmedVideo(trimmedBlob);
        } else {
          console.log("Bitrate is sufficient; skipping conversion.");
          setConvertedVideoURL(URL.createObjectURL(trimmedBlob));
          setIsTrimming(false);
          setDisableUploadBtn(false);
          setProgress(100);
          setIsTrimmedVideoConverted(true);
        }
      };

      // Fallback if metadata is not loaded within 2 seconds
      setTimeout(async () => {
        if (!metadataLoaded) {
          console.log(
            "Metadata not available, converting trimmed video by default."
          );
          await convertTrimmedVideo(trimmedBlob);
        }
      }, 2000);

      videoElement.load();
    } catch (error) {
      console.error("Error trimming and converting video:", error);
    }
  };

  // Helper function for converting trimmed video
  const convertTrimmedVideo = async (trimmedBlob) => {
    console.log("Starting trimmed video conversion...");
    const ffmpeg = new FFmpeg();
    await ffmpeg.load();
    setProgress(75); // Start conversion progress

    await ffmpeg.writeFile(
      "trimmed_input.mp4",
      new Uint8Array(await trimmedBlob.arrayBuffer())
    );
    await ffmpeg.exec([
      "-i",
      "trimmed_input.mp4",
      "-b:v",
      "500k",
      "trimmed_output_high_bitrate.mp4",
    ]);

    const convertedData = await ffmpeg.readFile(
      "trimmed_output_high_bitrate.mp4"
    );
    const highBitrateBlob = new Blob([convertedData.buffer], {
      type: "video/mp4",
    });
    setConvertedVideoURL(URL.createObjectURL(highBitrateBlob));

    setIsTrimming(false);
    setDisableUploadBtn(false);
    setProgress(100); // Conversion complete
    setIsTrimmedVideoConverted(true);
    console.log("Trimmed video conversion completed.");
  };

  const updateVideoPreview = (startTime, endTime) => {
    const videoElement = document.getElementById("videoElement");

    if (videoElement) {
      videoElement.currentTime = startTime;
      videoElement.pause(); // Start playback

      const checkTime = () => {
        // Stop playback if the end time is reached
        if (videoElement.currentTime >= endTime) {
          videoElement.pause();
        } else {
          requestAnimationFrame(checkTime); // Continue checking time
        }
      };

      // Start checking
      requestAnimationFrame(checkTime);
    }
  };

  // useEffect(() => {
  //   const video = videoCropRef.current;

  //   if (!video || !convertedVideoURL) return;

  //   const onLoadedMetadata = () => {
  //     const container = videoContainerRef.current;
  //     if (!container) return;

  //     const containerWidth = container.offsetWidth;
  //     const containerHeight = container.offsetHeight;
  //     const aspectRatio = video.videoWidth / video.videoHeight;

  //     let displayWidth = containerWidth;
  //     let displayHeight = containerWidth / aspectRatio;

  //     if (displayHeight > containerHeight) {
  //       displayHeight = containerHeight;
  //       displayWidth = containerHeight * aspectRatio;
  //     }

  //     const offsetX = (containerWidth - displayWidth) / 2;
  //     const offsetY = (containerHeight - displayHeight) / 2;

  //     setVideoDimensions({
  //       naturalWidth: video.videoWidth,
  //       naturalHeight: video.videoHeight,
  //       displayWidth,
  //       displayHeight,
  //       offsetX,
  //       offsetY,
  //     });

  //     setVideoCrop({
  //       x: offsetX,
  //       y: offsetY,
  //       width: displayWidth,
  //       height: displayHeight,
  //     });
  //   };

  //   // Attach event listener
  //   video.addEventListener('loadedmetadata', onLoadedMetadata);

  //   // Cleanup on unmount or if video changes
  //   return () => {
  //     video.removeEventListener('loadedmetadata', onLoadedMetadata);
  //   };
  // }, [convertedVideoURL]);
  // const onVideoLoaded = () => {
  //   const video = videoCropRef.current;
  //   const originalWidth = video.videoWidth;
  //   const originalHeight = video.videoHeight;
  //   setOriginalDimensions({ width: originalWidth, height: originalHeight });

  //   const containerWidth = video.offsetWidth;
  //   const containerHeight = video.offsetHeight;
  //   const aspectRatio = originalWidth / originalHeight;

  //   let renderedWidth = containerWidth;
  //   let renderedHeight = containerWidth / aspectRatio;

  //   if (renderedHeight > containerHeight) {
  //     renderedHeight = containerHeight;
  //     renderedWidth = containerHeight * aspectRatio;
  //   }

  //   const offsetLeft = (containerWidth - renderedWidth) / 2;
  //   const offsetTop = (containerHeight - renderedHeight) / 2;

  //   setDisplayDimensions({
  //     width: renderedWidth,
  //     height: renderedHeight,
  //     offsetLeft,
  //     offsetTop,
  //   });
  // };
  const onVideoLoaded = () => {
    const video = videoCropRef.current;
    const originalWidth = video.videoWidth;
    const originalHeight = video.videoHeight;
    setOriginalDimensions({ width: originalWidth, height: originalHeight });

    const containerWidth = video.offsetWidth;
    const containerHeight = video.offsetHeight;
    const aspectRatio = originalWidth / originalHeight;

    let renderedWidth = containerWidth;
    let renderedHeight = containerWidth / aspectRatio;

    if (renderedHeight > containerHeight) {
      renderedHeight = containerHeight;
      renderedWidth = containerHeight * aspectRatio;
    }

    const offsetLeft = (containerWidth - renderedWidth) / 2;
    const offsetTop = (containerHeight - renderedHeight) / 2;

    setDisplayDimensions({
      width: renderedWidth,
      height: renderedHeight,
      offsetLeft,
      offsetTop,
    });

    // Set initial crop position and size to cover center of video
    const initialCropWidth = Math.min(renderedWidth * 0.8, 400); // 80% of video width or max 400px
    const initialCropHeight = Math.min(renderedHeight * 0.8, 400); // 80% of video height or max 400px

    setVideoCrop({
      x: offsetLeft + (renderedWidth - initialCropWidth) / 2,
      y: offsetTop + (renderedHeight - initialCropHeight) / 2,
      width: initialCropWidth,
      height: initialCropHeight,
    });
  };

  const convertToOriginalDimensions = (x, y, width, height) => {
    const adjustedX = Math.max(x - displayDimensions.offsetLeft, 0);
    const adjustedY = Math.max(y - displayDimensions.offsetTop, 0);
    const adjustedWidth = Math.min(width, displayDimensions.width - adjustedX);
    const adjustedHeight = Math.min(
      height,
      displayDimensions.height - adjustedY
    );

    const scaleX = originalDimensions.width / displayDimensions.width;
    const scaleY = originalDimensions.height / displayDimensions.height;

    return {
      x: Math.round(adjustedX * scaleX),
      y: Math.round(adjustedY * scaleY),
      width: Math.round(adjustedWidth * scaleX),
      height: Math.round(adjustedHeight * scaleY),
    };
  };

  // Process video using FFmpeg
  // const handleCrop = async () => {
  //   if (videoCropProcessing) return;
  //   setVideoCropProcessing(true);

  //   try {
  //     const ffmpeg = new FFmpeg({
  //       corePath:
  //         "https://cdn.jsdelivr.net/npm/@ffmpeg/ffmpeg@0.12.13/dist/umd/ffmpeg.min.js", // Test with CDN
  //     });
  //     await ffmpeg.load();
  //     const videoBlob = await fetch(convertedVideoURL).then(res => res.blob());
  //     ffmpeg.FS('writeFile', 'input.mp4', await fetchFile(videoBlob));

  //     const { x, y, width, height } = convertToOriginalDimensions(
  //       videoCrop.x,
  //       videoCrop.y,
  //       videoCrop.width,
  //       videoCrop.height
  //     );

  //     await ffmpeg.run(
  //       '-i', 'input.mp4',
  //       '-filter:v', `crop=${width}:${height}:${x}:${y}`,
  //       '-c:a', 'copy',
  //       'output.mp4'
  //     );

  //     const data = ffmpeg.FS('readFile', 'output.mp4');
  //     const url = URL.createObjectURL(new Blob([data.buffer], { type: 'video/mp4' }));

  //     // Trigger download
  //     const a = document.createElement('a');
  //     a.href = url;
  //     a.download = 'cropped-video.mp4';
  //     a.click();
  //     URL.revokeObjectURL(url);
  //   } catch (error) {
  //     console.error('Error processing video:', error);
  //   } finally {
  //     setVideoCropProcessing(false);
  //   }
  // };
  const handleCrop = async () => {
    if (videoCropProcessing) return;
    setVideoCropProcessing(true);

    try {
      const ffmpeg = new FFmpeg({
        corePath:
          "https://cdn.jsdelivr.net/npm/@ffmpeg/ffmpeg@0.12.13/dist/umd/ffmpeg.min.js", // Use CDN
      });
      await ffmpeg.load();

      // Fetch the video as a blob and write it to ffmpeg
      const videoBlob = await fetch(convertedVideoURL).then((res) =>
        res.blob()
      );
      await ffmpeg.writeFile(
        "input.mp4",
        new Uint8Array(await videoBlob.arrayBuffer())
      );

      // Get crop dimensions from original video dimensions
      const { x, y, width, height } = convertToOriginalDimensions(
        videoCrop.x,
        videoCrop.y,
        videoCrop.width,
        videoCrop.height
      );

      // Execute the ffmpeg crop command
      await ffmpeg.exec([
        "-i",
        "input.mp4",
        "-filter:v",
        `crop=${width}:${height}:${x}:${y}`,
        "-c:a",
        "copy",
        "output.mp4",
      ]);

      // Read the output file and create a Blob
      const croppedData = await ffmpeg.readFile("output.mp4");
      const croppedBlob = new Blob([croppedData.buffer], { type: "video/mp4" });

      // Generate a URL for the cropped video
      // const croppedVideoURL = URL.createObjectURL(croppedBlob);
      setConvertedVideoURL(URL.createObjectURL(croppedBlob));
      // Trigger download (optional)
      // const a = document.createElement("a");
      // a.href = croppedVideoURL;
      // a.download = "cropped-video.mp4";
      // a.click();
      // URL.revokeObjectURL(croppedVideoURL); // Revoke the URL after download
      setCropVideoOn(false);
    } catch (error) {
      console.error("Error cropping video:", error);
    } finally {
      setVideoCropProcessing(false);
      setCropVideoOn(false);
    }
  };

  const sendAttachDocsHandle = (event) => {
    setAddAttachActive(false);
    if (event.target.files && event.target.files.length > 0) {
      setSelectedDoc(event.target.files[0]);
      setOpenAttachDocsModal(true);
    }
  };

  const uploadDocument = async (e) => {
    let fd = new FormData();
    fd.append("uploadFile", selectedDoc);
    fd.append("location", "home");

    try {
      const response = await axios({
        url: "uploadfile",
        method: "post",
        data: fd,
        withCredentials: false,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
        },
      });

      if (response.data.data) {
        const message = {
          userID: profile?.id || 0,
          groupID: props.activeChatRoom,
          userName: profile?.username,
          MessageType: "document",
          document: response.data.data[0],
          userImage: profile?.userImage || "",
          //   isPrivate: answerPrivacy,
          isReply: !!replyingMessage, // Indicate if this is a reply
          replyTo: replyingMessage
            ? {
                chatId: replyingMessage.chatId || 0,
                userName: replyingMessage.userName || "",
                userImage: replyingMessage.userImage || "",
                message: replyingMessage.message || "",
                messageType: replyingMessage.messageType || "message",
                imageUrl: replyingMessage.images || "",
                videoUrl: replyingMessage.videos || "",
                videoThumbnail: replyingMessage.videoThumbnail || "",
                audioUrl: replyingMessage.audio || "",
                documentUrl: replyingMessage.documents || "",
              }
            : null,
        };

        console.log("Document is being sent", message);

        if (profile?.id) {
          sendMessage(message);
          setIsScrollToBottom(true);
          setChatMessage("");
          setTextareaHeight(50);
          setSelectedDoc([]);
          setReplyingMessage(null);
        }
        setOpenAttachDocsModal(false);
      } else {
        console.error("Unsupported file format!!!");
      }
    } catch (error) {
      console.error("Error uploading document:", error);
      // Handle additional error cases here
    }
  };

  //   const whiteBoardSubmit = async (file) => {
  //     let fd = new FormData();
  //     fd.append("uploadFile", file);
  //     fd.append("location", "home");

  //     const response = await axios({
  //       url: "uploadfile",
  //       method: "post",
  //       data: fd,
  //       withCredentials: false,
  //       headers: {
  //         "Content-Type": "multiplart/form-data",
  //         Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
  //       },
  //     });

  //     if (response.data.data) {
  //       const message = {
  //         userID: profile?.id || 0,
  //         groupID: props.activeChatRoom,
  //         userName: profile?.username,
  //         MessageType: "whiteboard",
  //         image: response.data.data[0],
  //         userImage: profile?.userImage || "",
  //       };
  //       console.log("white Board is being sent", message);

  //       if (profile?.id) {
  //         // props.socket.emit("chat_message", obj);
  //         sendMessage(message);
  //         setIsScrollToBottom(true);
  //         setChatMessage("");
  //         setTextareaHeight(50);
  //         setSelectedImage([]);

  //         // setTimeout(() => props.getChatHistory(props.activeChatRoom), 1000)
  //       }
  //     } else {
  //       /// todo : handle error here....
  //       console.log("Unsupported file format!!!");
  //     }
  //   };

  const whiteBoardSubmit = async (file) => {
    let fd = new FormData();
    fd.append("uploadFile", file);
    fd.append("location", "home");

    try {
      const response = await axios({
        url: "uploadfile",
        method: "post",
        data: fd,
        withCredentials: false,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
        },
      });

      if (response.data.data) {
        const message = {
          userID: profile?.id || 0,
          groupID: props.activeChatRoom,
          userName: profile?.username,
          MessageType: "whiteboard",
          image: response.data.data[0],
          userImage: profile?.userImage || "",
          //   isPrivate: answerPrivacy,
          isReply: !!replyingMessage, // Indicate if this is a reply
          replyTo: replyingMessage
            ? {
                chatId: replyingMessage.chatId || 0,
                userName: replyingMessage.userName || "",
                userImage: replyingMessage.userImage || "",
                message: replyingMessage.message || "",
                messageType: replyingMessage.messageType || "message",
                imageUrl: replyingMessage.images || "",
                videoUrl: replyingMessage.videos || "",
                videoThumbnail: replyingMessage.videoThumbnail || "",
                audioUrl: replyingMessage.audio || "",
                documentUrl: replyingMessage.documents || "",
              }
            : null,
        };

        console.log("Whiteboard is being sent", message);

        if (profile?.id) {
          sendMessage(message);
          setIsScrollToBottom(true);
          setChatMessage("");
          setTextareaHeight(50);
          setSelectedImage([]);
          setReplyingMessage(null); // Reset replying message after sending
        }
      } else {
        console.error("Unsupported file format!!!");
      }
    } catch (error) {
      console.error("Error uploading whiteboard content:", error);
      // Handle additional error cases here
    }
  };

  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[arr.length - 1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const onImageSave = async (editedImageObject, designState) => {
    const imageCropFile = await dataURLtoFile(
      editedImageObject.imageBase64,
      editedImageObject.fullName
    );
    console.log("saved", imageCropFile);
    submitImage(imageCropFile);
    return true;
  };

  // Helper function to convert base64 to Blob
  const base64ToBlob = (base64) => {
    const [metadata, data] = base64.split(",");
    const mimeType = metadata.match(/:(.*?);/)[1];
    const binary = atob(data);
    const array = new Uint8Array(binary.length);
    for (let i = 0; i < binary.length; i++) {
      array[i] = binary.charCodeAt(i);
    }
    return new Blob([array], { type: mimeType });
  };

  const resetStates = () => {
    setChatMessage("");
    setTextareaHeight(50);
    imageCloseModalHandle();
    setSelectedImage([]);
    setConvertedVideoURL(null);
    setProgress(0);
    setVideoThumbnail(null);
    setReplyingMessage(null);
    setSendingImg(false);
  };

  const handleVideoUpload = async (videoBlob) => {
    const fd = new FormData();
    const uniqueName = `video_${Date.now()}_${Math.floor(
      Math.random() * 1000000
    )}.mp4`;
    fd.append("uploadFile", videoBlob, uniqueName);
    fd.append("location", "home");

    try {
      const response = await axios({
        url: "uploadfile",
        method: "post",
        data: fd,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
        },
      });

      if (response.data.data) {
        console.log("Video uploaded successfully:", response.data.data[0]);
        return response.data.data[0]; // Return the uploaded video URL
      }
    } catch (error) {
      console.error("Error uploading video:", error);
      return null;
    }
  };

  const uploadMediaFile = async (mediaFile) => {
    const fd = new FormData();
    fd.append("uploadFile", mediaFile);
    fd.append("location", "home");

    try {
      const response = await axios({
        url: "uploadfile",
        method: "post",
        data: fd,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
        },
      });

      if (response.data.data && response.data.data.length > 0) {
        return response.data.data[0]; // Return the uploaded file URL
      }
    } catch (error) {
      console.error("Error uploading media file:", error);
    }

    return null;
  };

  const submitImage = async (mediaContent, clinicalNote, messageType) => {
    let videoThumbnailS3Url = null;

    try {
      if (convertedVideoURL) {
        // Upload video with thumbnail if applicable
        const videoBlob = await fetch(convertedVideoURL).then((res) =>
          res.blob()
        );

        if (videoThumbnail) {
          const thumbnailBlob = await fetch(videoThumbnail).then((res) =>
            res.blob()
          );
          const thumbnailFile = new File(
            [thumbnailBlob],
            `thumbnail_${Date.now()}.jpg`,
            {
              type: thumbnailBlob.type,
            }
          );

          // Upload the thumbnail and get the URL
          videoThumbnailS3Url = await uploadMediaFile(thumbnailFile);
        }

        const uploadedVideoURL = await handleVideoUpload(videoBlob);
        if (uploadedVideoURL) {
          const message = {
            userID: profile?.id || 0,
            groupID: props.activeChatRoom,
            userName: profile?.username,
            MessageType: "video",
            video: uploadedVideoURL,
            videoThumbnail: videoThumbnailS3Url || "",
            userImage: profile?.userImage || "",
          };

          sendMessage(message);
          resetStates();
        }
      } else {
        if (messageType === "images-notes" && Array.isArray(mediaContent)) {
          // Construct the payload for multiple images
          const payload = {
            multipleimage: {
              MessageType: "images-notes",
              imagesWithNotes: mediaContent, // Pass the imagesWithNotes array as-is
              userName: profile?.username || "",
              groupID: props.activeChatRoom,
              firstName: profile?.firstname || "",
              lastName: profile?.firstname || "",
              userImage: profile?.userImage || "",
            },
          };

          console.log("Payload for multiple images:", payload);

          // Send the message
          sendMessage(payload);
          resetStates();
        } else {
          // Construct replyTo object if replying
          const replyToMessage = replyingMessage
            ? {
                chatId: replyingMessage.chatId || 0,
                userName: replyingMessage.userName || "",
                userImage: replyingMessage.userImage || "",
                message: replyingMessage.message || "",
                messageType: replyingMessage.messageType || "message",
                imageUrl: replyingMessage.images || "",
                videoUrl: replyingMessage.videos || "",
                videoThumbnail: replyingMessage.videoThumbnail || "",
                audioUrl: replyingMessage.audio || "",
                documentUrl: replyingMessage.documents || "",
              }
            : null;

          // Construct the message object
          const message = {
            userID: profile?.id || 0,
            groupID: props.activeChatRoom,
            userName: profile?.username,
            clinicalNotes: clinicalNote || "",
            MessageType: messageType,
            [messageType === "images-notes" ? "imagesWithNotes" : messageType]:
              mediaContent,
            videoThumbnail: videoThumbnailS3Url || "",
            userImage: profile?.userImage || "",
            isReply: !!replyToMessage,
            replyTo: replyToMessage,
          };

          console.log("Media is being sent:", message);

          // Send the message
          if (profile?.id) {
            sendMessage(message);
            resetStates();
          }
        }
      }
    } catch (error) {
      console.error("Error sending media message:", error);
      setSendingImg(false);
    }
  };

  const sendEditedImgMsg = async () => {
    setSendingImg(true);

    if (multipleImage && multipleImage.length > 0) {
      try {
        const imagesWithNotes = [];
        for (const imgObj of multipleImage) {
          let imageFile = imgObj.imageFile;

          // Convert base64 or blob URL to File if necessary
          if (imageFile.startsWith("data:image")) {
            const blob = base64ToBlob(imageFile);
            imageFile = new File([blob], `edited_image_${Date.now()}.jpg`, {
              type: blob.type,
            });
          } else if (imageFile.startsWith("blob:")) {
            const blob = await fetch(imageFile).then((res) => res.blob());
            imageFile = new File([blob], `image_${Date.now()}.jpg`, {
              type: blob.type,
            });
          }

          // Upload the image and get the URL
          const uploadedImageUrl = await uploadMediaFile(imageFile);
          if (uploadedImageUrl) {
            imagesWithNotes.push({
              imageUrl: uploadedImageUrl,
              note: imgObj.message || "", // Add clinical note if available
            });
          }
        }

        if (imagesWithNotes.length === 1) {
          // Send as a single image
          await submitImage(
            imagesWithNotes[0].imageUrl,
            imagesWithNotes[0].note,
            "image"
          );
        } else if (imagesWithNotes.length > 1) {
          // Send as images-notes message type
          await submitImage(imagesWithNotes, null, "images-notes");
        }
      } catch (error) {
        console.error("Error processing images:", error);
      }
    }

    setSendingImg(false);
  };

  const getCameraPermission = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true, // Request audio along with video
      });
      setPermissionGranted(true);
      if (videoRecordRef.current) {
        videoRecordRef.current.srcObject = stream;
        videoRecordRef.current.muted = true;
        videoRecordRef.current.play();
      }
      mediaRecorderRef.current = new MediaRecorder(stream);
      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          recordedChunksRef.current.push(event.data);
        }
      };
      mediaRecorderRef.current.onstop = () => {
        const blob = new Blob(recordedChunksRef.current, { type: "video/mp4" });
        const url = URL.createObjectURL(blob);
        setRecordedVideoURL(url);
        recordedChunksRef.current = [];
      };
    } catch (error) {
      console.error("Error accessing camera or microphone:", error);
      alert(
        "Unable to access the camera or microphone. Please check your browser settings."
      );
    }
  };

  // const startRecording = () => {
  //   if (mediaRecorderRef.current) {
  //     recordedChunksRef.current = [];
  //     mediaRecorderRef.current.start();
  //     setRecording(true);
  //   }
  // };

  // const stopRecording = () => {
  //   if (mediaRecorderRef.current && recording) {
  //     mediaRecorderRef.current.stop();
  //     setRecording(false);
  //     setPermissionGranted(false);
  //   }
  //   if (videoRecordRef.current && videoRecordRef.current.srcObject) {
  //     const tracks = videoRecordRef.current.srcObject.getTracks();
  //     tracks.forEach((track) => track.stop());
  //     videoRecordRef.current.srcObject = null;
  //   }
  // };

  // const camCloseModalHandle = () => {
  //   setOpenCamModal(false);
  //   setPermissionGranted(false);
  //   setRecordedVideoURL(null)
  //   if (videoRecordRef.current && videoRecordRef.current.srcObject) {
  //     const tracks = videoRecordRef.current.srcObject.getTracks();
  //     tracks.forEach((track) => track.stop());
  //     videoRecordRef.current.srcObject = null;
  //   }
  // };
  const startRecording = () => {
    if (mediaRecorderRef.current) {
      recordedChunksRef.current = [];
      mediaRecorderRef.current.start();
      setRecording(true);
      setRecordingTime(0);
      const interval = setInterval(() => {
        setRecordingTime((prevTime) => prevTime + 1);
      }, 1000);
      setTimerInterval(interval);
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && recording) {
      mediaRecorderRef.current.stop();
      setRecording(false);
      setPermissionGranted(false);
      clearInterval(timerInterval);
      setTimerInterval(null);
    }
    if (videoRecordRef.current && videoRecordRef.current.srcObject) {
      const tracks = videoRecordRef.current.srcObject.getTracks();
      tracks.forEach((track) => track.stop());
      videoRecordRef.current.srcObject = null;
    }
  };
  const formatTimeRecord = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes}:${seconds.toString().padStart(2, "0")}`; // Format as MM:SS
  };
  const camCloseModalHandle = () => {
    // if (mediaRecorderRef.current) {

    //   startRecording()
    // }
    setOpenCamModal(false);
    setPermissionGranted(false);
    setRecording(false);
    setRecordedVideoURL(null);
    if (videoRecordRef.current && videoRecordRef.current.srcObject) {
      const tracks = videoRecordRef.current.srcObject.getTracks();
      tracks.forEach((track) => track.stop());
      videoRecordRef.current.srcObject = null;
      clearInterval(timerInterval);
      setTimerInterval(null);
      setRecordingTime(0);
    }
  };

  const submitVideo = async () => {
    try {
      console.log("Recorded Video:", recordedVideoURL);
      if (!recordedVideoURL) {
        console.error("No video to upload!");
        return;
      }

      const mediaBlob = await fetch(recordedVideoURL).then((response) =>
        response.blob()
      );
      const videoFile = new File([mediaBlob], `video_${Date.now()}.mp4`, {
        type: "video/mp4",
      });

      let fd = new FormData();
      fd.append("uploadFile", videoFile);
      fd.append("location", "home");

      const response = await axios({
        url: "uploadfile",
        method: "post",
        data: fd,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
        },
      });

      if (response.data?.data?.[0]) {
        const message = {
          userID: profile?.id || 0,
          groupID: props.activeChatRoom,
          userName: profile?.username,
          MessageType: "video",
          video: response.data.data[0],
          userImage: profile?.userImage || "",
          //   status: "active",
          isReply: !!replyingMessage, // Indicate if this is a reply
          replyTo: replyingMessage
            ? {
                chatId: replyingMessage.chatId || 0,
                userName: replyingMessage.userName || "",
                userImage: replyingMessage.userImage || "",
                message: replyingMessage.message || "",
                messageType: replyingMessage.messageType || "message",
                imageUrl: replyingMessage.images || "",
                videoUrl: replyingMessage.videos || "",
                videoThumbnail: replyingMessage.videoThumbnail || "",
                audioUrl: replyingMessage.audio || "",
                documentUrl: replyingMessage.documents || "",
              }
            : null,
        };

        console.log("Sending Video Message:", message);
        sendMessage(message);
        setIsScrollToBottom(true);
        setChatMessage("");
        setTextareaHeight(50);
        setOpenCamModal(false);
        setRecordedVideo([]);
      } else {
        console.error("File upload failed!");
      }
    } catch (error) {
      console.error("Error during video submission:", error);
    }
  };

  //   const submitVideo = async () => {
  //     console.log("recordedVideo", recordedVideo);
  //     const mediaBlob = await fetch(recordedVideo).then((response) =>
  //       response.blob()
  //     );
  //     const videoFile = new File([mediaBlob], "chat_video_recording.mp4", {
  //       type: "video/mp4",
  //     });
  //     console.log("Video File", videoFile);

  //     let fd = new FormData();
  //     fd.append("uploadFiles", videoFile);

  //     const response = await axios({
  //       url: "uploadFile",
  //       method: "post",
  //       data: fd,
  //       withCredentials: false,
  //       headers: {
  //         "Content-Type": "multiplart/form-data",
  //         Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
  //       },
  //     });

  //     if (response.data.data) {
  //     //   const obj = {
  //     //     conversationId: "",
  //     //     userId: profile?.id || 0,
  //     //     message: "",
  //     //     images: "",
  //     //     videos: response.data.data[0],
  //     //     groupId: props.activeChatRoom,
  //     //     status: "active",
  //     //   };

  //       const message = {
  //         userID: profile?.id || 0,
  //         groupID: props.activeChatRoom,
  //         userName: profile?.username,
  //         MessageType: "videoRecord",
  //         videos: response.data.data[0],
  //         userImage: profile?.userImage || "",
  //         // isPrivate: answerPrivacy,
  //         tatus: "active",
  //       };

  //       console.log("video note is being sent", message);

  //       // Send the message (emit to socket or other logic)

  //       if (profile?.id) {
  //         // props.socket.emit("chat_message", obj);
  //         sendMessage(message);
  //         setIsScrollToBottom(true);
  //         setChatMessage("");
  //         setTextareaHeight(50);
  //         setOpenCamModal(false);
  //         setRecordedVideo([]);
  //         // setTimeout(() => props.getChatHistory(props.activeChatRoom), 1000)
  //       }
  //     } else {
  //       /// todo : handle error here....
  //       console.log("Unsupported file format!!!");
  //     }
  //   };

  const audioCloseModalHandle = () => {
    setOpenAudioModal(false);
    resetAudioState();
  };

  const resetAudioState = () => {
    setAudioRecordingStatus("inactive");
    setRecordedAudio(null);
    setAudioPermission(false);
    audioChunks.current = [];
  };

  // Function to get audio recording permissions
  const getAudioPermission = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      liveAudioFeedRef.current.srcObject = stream;
      setAudioPermission(true);
    } catch (err) {
      console.error("Permission denied for audio recording:", err);
    }
  };

  const startAudioRecording = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        const recorder = new RecordRTC(stream, {
          type: "audio",
          mimeType: "audio/wav", // You can also use 'audio/webm' or 'audio/ogg'
          recorderType: RecordRTC.StereoAudioRecorder, // For high-quality audio
        });

        recorder.startRecording();
        setAudioRecordingStatus("recording");

        recorder.stream = stream; // Save the stream to stop it later

        mediaRecorderRef.current = recorder;
      })
      .catch((err) => console.error("Error accessing audio stream: ", err));
  };

  const stopAudioRecording = () => {
    mediaRecorderRef.current.stopRecording(() => {
      const audioBlob = mediaRecorderRef.current.getBlob();
      const audioUrl = URL.createObjectURL(audioBlob);
      setRecordedAudio(audioUrl);
      setAudioRecordingStatus("stopped");
    });
  };

  const submitAudio = async (file) => {
    console.log("Submitting audio:", recordedAudio);

    // Convert the blob URL to Blob object
    const blob = await fetch(recordedAudio).then((res) => res.blob());

    // Generate a unique file name
    const uniqueFileName = `audio_${Date.now()}.mp3`; // Example: audio_1698066423000.mp3

    // Create FormData and append the file
    let fd = new FormData();
    fd.append("uploadFile", blob, uniqueFileName);
    fd.append("location", "home");

    try {
      const response = await axios({
        url: "uploadfile",
        method: "post",
        data: fd,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
        },
      });

      //   console.log(response);

      if (response.data.data) {
        audioCloseModalHandle();

        const message = {
          userID: profile?.id || 0,
          groupID: props.activeChatRoom,
          userName: profile?.username,
          MessageType: "audio",
          audio: response.data.data[0],
          userImage: profile?.userImage || "",
          //   isPrivate: answerPrivacy,
        };

        console.log("Voice note is being sent", message);

        // Send the message (emit to socket or other logic)
        sendMessage(message);
        setIsScrollToBottom(true);
      } else {
        console.error("Unsupported file format!!!");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const imageClicked = (popItem) => {
    setOpenImageGalleryModal(true);

    const allMedia = chatHistory[props.activeChatRoom]
      .filter((i) => i.images || i.videos)
      .map((e) => (e.images ? e.images : e.videos)); // Collect both images and videos

    const activeIndex = allMedia.findIndex((e) => e === popItem);

    setCarouselActiveIndex(activeIndex);
    console.log("Pop-up ActiveIndex is", popItem);
  };

  const closeImageGalleryModal = () => {
    setOpenImageGalleryModal(false);
  };

  const onCarouselMenuItemclick = (carouselActiveIndex) => {
    setCarouselActiveIndex(carouselActiveIndex);
  };

  const leftCarouselIndex = () => {
    if (carouselActiveIndex > 0) {
      setCarouselActiveIndex(carouselActiveIndex - 1);
    }
  };
  // const rightCarouselIndex = () => {
  //   const allImages = chatHistory[props.activeChatRoom]
  //     .filter((i) => i.images !== "")
  //     .map((e) => e.images);
  //   if (carouselActiveIndex < allImages.length - 1) {
  //     setCarouselActiveIndex(carouselActiveIndex + 1);
  //     // setNextButtonDisable(true);
  //   }
  // };
  const rightCarouselIndex = () => {
    const allItems =
      chatHistory[props.activeChatRoom]?.filter(
        (i) => i.images !== "" || i.videos !== ""
      ) || [];

    if (carouselActiveIndex < allItems.length - 1) {
      setCarouselActiveIndex(carouselActiveIndex + 1);
    }
  };
  useEffect(() => {
    setIsScrollToBottom(true);
  }, [props.activeChatRoom, groups]);

  useEffect(() => {
    // scrollToBottom();
    if (isScrollToBottom) {
      scrollToBottom();
    }
  }, [chatHistory, isScrollToBottom]);

  useEffect(() => {
    if (props.isChatLoading) {
      setTimeout(() => {
        props.updateChatLoading(false);
      }, 5000);
    }
  }, [props.isChatLoading]);

  useEffect(() => {
    console.log("groups:", groups);
    console.log("props.activeChatRoom:", props.activeChatRoom);
    if (groups.length !== 0 && props.activeChatRoom !== null) {
      const activeRoomDetails = groups.find(
        (room) => room.id === parseInt(props.activeChatRoom)
      );

      console.log("activeRoomDetails", activeRoomDetails);
      if (activeRoomDetails !== undefined) {
        setActiveRooms(activeRoomDetails);
        setActiveChatRoomGloabal(activeRoomDetails);
        setIsArchived(activeRoomDetails.archive);
        setActiveCaseName(activeRoomDetails.name);
        scrollToBottom();
        setChatMessage("");
        setTextareaHeight(50);
        props.updateChatLoading(true);
      }
    }
  }, [props.activeChatRoom, groups]);

  useEffect(() => {
    if (routeParams?.id && routeParams?.id !== ("" || undefined || null)) {
      let id = parseInt(routeParams.id);
      props.updateActiveChat(id);
    }
  }, [routeParams]);

  // const handleBuyNowClick = async (room) => {
  //   const headers = {
  //     headers: {
  //       Authorization: "Bearer " + localStorage.getItem("jwtTokenApi2"),
  //       "Content-Type": "application/json",
  //     },
  //   };

  //   try {
  //     const paymentLinkForProduct = await axios.get(
  //       `${BaseURL}/payment/paymentLinks`,
  //       headers
  //     );
  //     const matchingProduct = paymentLinkForProduct.data.data.find(
  //       (product) => product.description === room.name
  //     );
  //     const matchingProductlink = matchingProduct.payment_url;
  //     if (paymentLinkForProduct) {
  //       window.location.assign(matchingProductlink);
  //     } else {
  //       console.log("there is not paymentLinkForProduct");
  //     }
  //   } catch (error) {
  //     // Handle any errors that occurred during the request
  //     console.error("Error fetching data:", error.message);
  //   }
  // };

  const handleBuyNowClick = async () => {
    const payload = {
      groupId: String(activeRooms?.id),
      userId: profile?.id,
      userEmail: profile?.email,
      successUrl: "https://ir4u.info/chat-room",
      cancelUrl: "https://ir4u.info/chat-room",
    };

    try {
      const response = await axios.post(
        "https://staging-chats.ir4u.info/api/v1/create-checkout-session",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwtTokenApi1")}`,
            "Content-Type": "application/json",
          },
        }
      );

      // console.log("Checkout session created:", response.data);

      // Redirect to Stripe checkout URL
      if (response?.data?.url) {
        window.location.href = response.data.url;
      } else {
        console.error("Checkout URL is not available.");
      }
    } catch (error) {
      console.error(
        "Error creating checkout session:",
        error.response || error
      );
    }
  };

  const handleDeleteClick = (room) => {
    // console.log("room", room.chatId)
    deleteMessage(String(room.chatId));
    handleModalToggle();
  };

  const handleModalToggle = (item) => {
    if (item) {
      setShowModal(!showModal);
      setItemToDelete(item);
    } else {
      setShowModal(!showModal);
      setItemToDelete(null);
    }
  };
  const shareUrl =
    window.location.href + "/" + JSON.stringify(props.activeChatRoom);

  const handleCopyLink = async () => {
    await navigator.clipboard.writeText(shareUrl);
    setTooltipText(true);
    setTimeout(() => setTooltipText(false), 2000);
  };

  const uploadEditedImage = async () => {
    if (!chatImageEditedResult) {
      console.log("No edited image to upload.");
      return;
    }

    try {
      // Step 1: Fetch the Blob from the edited image URL
      const blob = await fetch(chatImageEditedResult).then((res) => res.blob());

      // Step 2: Generate a unique filename for the image
      const mimeType = blob.type; // Get the MIME type of the blob
      const extension = mimeType.split("/")[1]; // Extract file extension (e.g., 'png', 'jpeg')
      const uniqueFileName = `image_${Date.now()}_${Math.floor(
        Math.random() * 1000000
      )}.${extension}`;

      // Step 3: Prepare FormData for uploading
      let fd = new FormData();
      fd.append("uploadFile", blob, uniqueFileName);
      fd.append("location", "home"); // Add location if required by API

      setUploaded(null); // Reset upload progress

      // Step 4: Make the upload API request
      const response = await axios({
        url: "uploadfile",
        method: "post",
        data: fd,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
        },
        onUploadProgress: (data) => {
          setUploaded(Math.round((data.loaded / data.total) * 100));
          setDisableUploadBtn(true);
        },
      });

      console.log("Upload response:", response);

      if (response.data.data) {
        // Step 5: Construct the chat message object with the uploaded image URL
        const message = {
          userID: profile?.id || 0,
          groupID: props.activeChatRoom,
          userName: profile?.username,
          MessageType: "image",
          userImage: profile?.userImage || "",
          image: response.data.data[0], // API returned image URL
        };

        // Step 6: Emit the message to the chat
        if (profile?.id) {
          sendMessage(message); // Emit the message using your `sendMessage` function

          console.log("Image message sent to chat:", message);

          // Reset chat inputs and UI states
          setIsScrollToBottom(true);
          setChatMessage("");
          setTextareaHeight(50);
          setUploaded(0);
        }
      } else {
        console.log("Upload failed: Unsupported file format!");
      }
    } catch (error) {
      console.error("Error uploading edited image:", error);
    }
  };

  useEffect(() => {
    const fetchGroupUsers = async () => {
      try {
        const response = await axios({
          url: `/group/${props.activeChatRoom}/users`,
          method: "get",
          withCredentials: false,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
          },
        });

        console.log("response", response.data);
        setGroupUsers(response.data);

        if (groupUsers) {
          console.log("response1", groupUsers);
        }
      } catch (error) {
        console.error("Error fetching group users:", error);
      }
    };

    if (props?.activeChatRoom) {
      fetchGroupUsers();
    }
  }, [props?.activeChatRoom]);

  const handleEditClick = (imageURL) => {
    // imageCloseModalHandle()
    closeImageGalleryModal();
    setChatImageEditURL(imageURL);
    setIsImageEditorOpen(true);
  };

  // Function to format date headers
  const formatDateHeader = (date) => {
    const now = moment();
    const messageDate = moment(date);

    // console.log("Date received in formatdate function", date)

    // If date is invalid, return "Invalid date"
    if (!messageDate.isValid()) return "Invalid date";

    if (now.isSame(messageDate, "day")) return "Today";
    if (now.clone().subtract(1, "days").isSame(messageDate, "day"))
      return "Yesterday";
    return messageDate.format("D-MMM-YYYY");
  };

  const extractHourAndMin = (dateString) => {
    const date = moment(dateString);

    // Check if date is valid
    if (!date.isValid()) return "Invalid date";

    // Format the time as hh:mm AM/PM
    return date.format("h:mm A");
  };

  const filteredChatHistory = filterByQuestion
    ? chatHistory?.[props?.activeChatRoom]?.filter(
        (item) => item.messageType === "question"
      )
    : filteredUser
    ? chatHistory?.[props?.activeChatRoom]?.filter(
        (item) => item.userName === filteredUser
      )
    : chatHistory?.[props?.activeChatRoom];

  useEffect(() => {
    setFilteredUser(null); // Reset user filter when active chat room changes
    setFilterByQuestion(false); // Reset question filter when active chat room changes
    console.log("Chat History Updated:", chatHistory[props.activeChatRoom]);
  }, [props.activeChatRoom, chatHistory]);

  const handleDownload = (item) => {
    // Ensure we have a valid file URL (images or videos)
    const fileUrl = item?.images || item?.videos;
    if (!fileUrl) {
      console.error("No valid file URL available for download.");
      return;
    }

    // Extract the file name from the URL
    const fileNameFromUrl = fileUrl.split("/").pop();
    const timestamp = new Date().getTime();
    const uniqueFileName = fileNameFromUrl
      ? `${fileNameFromUrl
          .split(".")
          .slice(0, -1)
          .join("")}_${timestamp}.${fileNameFromUrl.split(".").pop()}`
      : `download_${timestamp}`; // Ensure fallback if the URL is malformed

    // Fetch the file as a blob
    fetch(fileUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch the file.");
        }
        return response.blob();
      })
      .then((blob) => {
        // Create a temporary URL for the blob
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = uniqueFileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // Clean up the temporary URL
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error downloading the file:", error);
      });
  };

  const handleClinicalNoteInputChange = (e) => {
    const value = e.target.value;
    if (value !== clinicalNote) setClinicalNote(value);
  };

  return (
    // <div
    //   className={`ir-chat-content-messages-main-container ${
    //     !chatSidebarOpen ? "ir-ws-chat-display-flex" : "ir-ws-chat-display-none"
    //   } w-100 overflow-hidden position-relative`}
    // >
    <div
      className={`ir-chat-content-messages-main-container ${
        !chatProfileOpen && !chatSidebarOpen
          ? "ir-ws-chat-display-flex"
          : "ir-ws-chat-display-none"
      } w-100 overflow-hidden position-relative`}
    >
      {isArchived === "true" || !profile.username === "admin" ? (
        <div className="buyNowBtnParent">
          <p>
            This room is Archived. To access this room please purchase by
            clicking below Buy Now button
          </p>
          {/* <button
            className="buyNowBtn"
            onClick={() => handleBuyNowClick(activeRooms)}
            // disabled={activeRooms?.paymentInfo?.paymentLink === null}
          >
            Buy Now
          </button> */}

          <div className="ir-ws-text-center ir-ws-default-btn-container">
            <button
              className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn"
              onClick={() => handleBuyNowClick(activeRooms)}
            >
              <span>Buy Now</span>
            </button>
          </div>
        </div>
      ) : (
        <>
          {props.isChatLoading && (
            <div className="loadingContainer">
              <div className="loadingIcon">
                <img src={LoadingImage} alt="loading..." />
              </div>
            </div>
          )}

          <div className="ir-chat-topbar">
            <div className="align-tem-center d-flex ir-chat-top-header">
              <div className="ir-chat-top-header-main-cont">
                <div className="ir-ws-chat-title-main-cont">
                  <div className=" ir-ws-chat-none-toggle">
                    <div
                      className="ir-chat-bg-btn"
                      onClick={() => {
                        handleSidebar();
                        setSearchTerm("");
                        setChatSearchActive(false);
                      }}
                    >
                      <i class="bi bi-arrow-left"></i>
                    </div>
                  </div>
                  {chatSearchActive ? (
                    <input
                      type="text"
                      className="ir-chat-search-input-ph"
                      placeholder="Search..."
                      value={searchTerm}
                      onChange={handleSearchChange}
                    />
                  ) : (
                    <div
                      className="ir-ws-width-100 "
                      onClick={() => {
                        // Check screen width before executing
                        if (window.innerWidth < 768) {
                          handleChatProfileOpen();
                          // handleGroupInfoClick();
                        }
                      }}
                    >
                      <h2>
                        {activeCaseName}
                        {window.innerWidth > 768 && (
                          <i
                            onClick={handleGroupInfoClick}
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            class="bi bi-info-circle"
                          ></i>
                        )}
                      </h2>
                    </div>
                  )}
                </div>

                <div className="ir-chat-top-head-elements">
                  <div className="top-nav-bar-menu-items-container">
                    <div
                      ref={searchRef}
                      className={`ir-chat-top-head-invite ir-ws-chat-flex-toggle ${
                        isSearchActive ? "active" : ""
                      }`}
                      onClick={(e) => e.stopPropagation()}
                      title="Search"
                    >
                      <i
                        className="bi bi-search"
                        onClick={handleSearchClick}
                      ></i>
                      <input
                        type="text"
                        className="ir-chat-search-input"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={handleSearchChange}
                      />
                    </div>
                    <div
                      className={`ir-chat-top-head-invite ir-ws-chat-flex-toggle`}
                      onClick={(e) => e.stopPropagation()}
                      title="Filter"
                    >
                      <i
                        class="bi bi-funnel"
                        onClick={() => {
                          setIsViewMoreMenuOpen(false);
                          toggleSearchMessagesByUserPopup();
                          setIsSearchActive(false);
                        }}
                      ></i>
                    </div>
                    <div
                      className="ir-chat-top-head-view-more"
                      ref={menuRef}
                      title="View More"
                    >
                      {!chatSearchActive ? (
                        <i
                          className="bi bi-three-dots-vertical"
                          onClick={toggleViewMoreMenu}
                        ></i>
                      ) : (
                        <i
                          className="bi bi-x-lg"
                          onClick={handleChatSearch}
                        ></i>
                      )}

                      {isViewMoreMenuOpen && (
                        <ul className="ir-chat-menu-dropdown">
                          <li
                            className="ir-chat-menu-item ir-chat-none-flex-toggle"
                            onClick={() => {
                              // setFilterByQuestion(!filterByQuestion);
                              handleSearchClick();
                              handleChatSearch();
                              setIsViewMoreMenuOpen(false);
                            }}
                          >
                            <i className="bi bi-search"></i>
                            Search message
                          </li>
                          <li
                            className="ir-chat-menu-item ir-chat-none-flex-toggle"
                            onClick={() => {
                              // setFilterByQuestion(!filterByQuestion);
                              toggleSearchMessagesByUserPopup();
                              setIsSearchActive(false);
                              setIsViewMoreMenuOpen(false);
                            }}
                          >
                            <i className="bi bi-funnel"></i>
                            Filter message
                          </li>
                          <li
                            className="ir-chat-menu-item"
                            onClick={() => {
                              setFilterByQuestion(!filterByQuestion);
                              setIsViewMoreMenuOpen(false);
                            }}
                          >
                            <i className="bi bi-question-circle"></i>
                            Messages by question
                          </li>
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {pinnedPrimary && (
                <>
                  <div className="ir-top-chat-bar-question-line"></div>
                  <div
                    className={`ir-top-chat-question-cont ${
                      isDetailsVisible ? "open" : ""
                    }`}
                  >
                    <div className="ir-ws-chat-question-text-cont">
                      <div className="ir-ws-chat-text-box">
                        {!isDetailsVisible ? (
                          <>
                            <p className="ir-chat-q-text">Q</p>
                            <p className="ir-chat-main-ques-text">
                              {pinnedPrimary?.displayMessage?.length > 90
                                ? `${pinnedPrimary?.displayMessage?.slice(
                                    0,
                                    90
                                  )}...`
                                : pinnedPrimary?.displayMessage}
                            </p>
                          </>
                        ) : (
                          <>
                            {currentQuestions.map((question, index) => (
                              <div
                                key={index}
                                ref={pinnedMessageRef}
                                className="active-question-container "
                              >
                                <p
                                  className="ir-chat-q-text"
                                  onClick={handleScrollToPinnedMessage}
                                >
                                  Q
                                </p>
                                <p
                                  className="ir-chat-main-ques-text"
                                  style={{ cursor: "pointer" }}
                                  onClick={handleScrollToPinnedMessage}
                                >
                                  {question.displayMessage}
                                </p>
                              </div>
                            ))}
                          </>
                        )}
                      </div>
                      <div className="ir-ws-question-img-main-cont">
                        {isDetailsVisible && (
                          <>
                            {currentQuestions.map((question, index) => (
                              <div
                                key={index}
                                ref={pinnedMessageRef}
                                className="ir-ws-chat-questio-expand-container"
                              >
                                {question.question.images?.length > 0 && (
                                  <div className="ir-ws-chat-questio-img-cont">
                                    <img
                                      className="ir-ws-chat-questio-img"
                                      src={question.question.images[0]}
                                      alt={`Question ${index}`}
                                      onClick={handleScrollToPinnedMessage}
                                    />
                                  </div>
                                )}
                              </div>
                            ))}
                            {/* {renderPagination()} */}
                          </>
                        )}

                        {!isDetailsVisible ? (
                          <i
                            class="bi bi-chevron-down ir-chat-top-chevron"
                            onClick={toggleQuestionAccordion}
                          ></i>
                        ) : (
                          <i
                            class="bi bi-chevron-up ir-chat-top-chevron  up-chevron"
                            onClick={toggleQuestionAccordion}
                          ></i>
                        )}
                      </div>
                    </div>
                    {isDetailsVisible && (
                      <div className="ir-chat-questtion-pagination-cont">
                        {renderPagination()}
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>

          {/* {isSynopsisVisible && synopsisData && (
            <div
              className={`ir-synopsis-sidebar ${
                isSynopsisVisible ? "show" : ""
              }`}
            >
              <div className="ir-synopsis-header">
                <h5 className="ir-synopsis-title">
                  Synopsis of {activeRooms.name}
                </h5>
                <button
                  type="button"
                  className="ir-synopsis-close-btn"
                  onClick={() => setIsSynopsisVisible(false)}
                >
                  X
                </button>
              </div>
              <div className="ir-synopsis-body">
                <h6>Details:</h6>
                <p>{synopsisData.details || "No details available"}</p>
                <h6>Objective:</h6>
                <p>{synopsisData.objective || "No objective available"}</p>
              </div>
            </div>
          )} */}

          {isSearchOverlayOpen && searchTerm.length > 0 && (
            <div ref={overlayRef} className="search-overlay">
              <div className="chat-history">
                {filteredMessages.length > 0 ? (
                  filteredMessages.map((msg) => (
                    <div
                      key={msg.id}
                      className="message-item"
                      onClick={() => handleMessageClick(msg.chatId)}
                    >
                      <div className="message-user">{msg.displayTime}</div>
                      <div className="message-content">
                        {highlightText(msg.message, searchTerm)}
                      </div>
                      <div className="message-time">{msg.userName}</div>
                    </div>
                  ))
                ) : (
                  <div className="no-results">No messages found.</div>
                )}
              </div>
            </div>
          )}

          <div
            className={`ir-chat-conversation p-3 p-lg-4 positin-relative ${
              isDragActive ? "drag-active" : ""
            }`}
            {...getRootProps({ onClick: (e) => e.stopPropagation() })}
          >
            {/* {filteredChatHistory?.length > 0 &&
                            updateChatTimes(filteredChatHistory) */}
            <div className="simplebar-content">
              {filteredChatHistory?.length <
                chatHistory[props?.activeChatRoom]?.length && (
                <div className="chat-header">
                  <div className="filter-info">
                    {filterByQuestion ? (
                      <span>These are filtered questions</span>
                    ) : (
                      <span>
                        These are filtered messages for {filteredUser}
                      </span>
                    )}

                    <button
                      className="clear-filters-btn"
                      onClick={() => {
                        setFilteredUser(null);
                        setFilterByQuestion(false);
                      }}
                    >
                      {/* <i className="bi bi-x-circle"></i> */}
                      Clear Filters
                    </button>
                  </div>
                </div>
              )}

              <ul className="list-unstyled chat-conversation-list">
                {/* {chatHistory?.[props?.activeChatRoom] &&
                        chatHistory[props?.activeChatRoom].length > 0 &&
                        updateChatTimes(chatHistory[props?.activeChatRoom]) */}
                {filteredChatHistory?.length > 0 &&
                  updateChatTimes(filteredChatHistory)
                    //   ?.filter((item) => item.userName && item.userName.trim() !== "")
                    .sort(
                      (a, b) => new Date(a?.createdOn) - new Date(b?.createdOn)
                    )
                    .map((item, i) => {
                      //   console.log("item", item, profile);
                      //   let str = item?.userName.toUpperCase();
                      //   let matches = str?.match(/\b(\w)/g); // ['J','S','O','N']
                      //   let profileDp = matches?.join(""); // JSON
                      let str = item?.userName?.toUpperCase();
                      let profileDp;

                      if (str?.includes(" ")) {
                        // Extract initials from multi-word names
                        let matches = str.match(/\b(\w)/g); // Match the first letter of each word
                        profileDp = matches?.join(""); // Join initials, e.g., "JADDU" -> "JD"
                      } else {
                        // Extract first two characters from single-word names
                        profileDp = str?.substring(0, 2); // e.g., "JADDU" -> "JA"
                      }

                      if (
                        item?.images !== "" &&
                        item?.images !== undefined &&
                        typeof item?.images !== "string"
                      ) {
                        item.images = JSON.parse(item.images);
                      }

                      const currentMessageDate = formatDateHeader(
                        item.createdOn
                      );

                      const previousMessageDate =
                        i > 0
                          ? formatDateHeader(
                              chatHistory[props.activeChatRoom][i - 1].createdOn
                            )
                          : null;
                      const isNewDate =
                        i === 0 || currentMessageDate !== previousMessageDate;

                      if (
                        !currentMessageDate ||
                        currentMessageDate === "Invalid date"
                      ) {
                        console.warn(
                          "Invalid date encountered for message ID:",
                          item
                        );
                      }

                      const messageTime = extractHourAndMin(item.createdOn);

                      return (
                        <React.Fragment key={i}>
                          {isNewDate && (
                            <li className="date-separator">
                              <div className="separator-content">
                                {currentMessageDate}
                              </div>
                            </li>
                          )}
                          <li
                            className={
                              "chat-list " +
                              (profile.username === item.userName &&
                                "chat-list-outgoing")
                            }
                            ref={(el) => {
                              if (el && pinnedMessageRef.current) {
                                pinnedMessageRef.current[item?.chatId] = el;
                              }
                            }}
                          >
                            {(item.isPrivate &&
                              (profile.username === item.userName ||
                                profile?.firstname === "admin" ||
                                profile?.lastname === "admin")) ||
                            !item.isPrivate ? (
                              <div className="conversation-list">
                                <div className="ir-chat-profile-dp-container">
                                  {item?.userImage?.trim() === "" ? (
                                    <p className="ir-chat-profile-dp">
                                      {profileDp}
                                    </p>
                                  ) : (
                                    <img
                                      className="chat-avatar"
                                      src={item?.userImage || NoImage}
                                      alt="profile pic"
                                      onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // Prevents infinite loop
                                        currentTarget.src = NoImage; // Fallback to a placeholder image
                                      }}
                                    />
                                  )}
                                </div>
                                <div
                                  className={`ir-chat-conv-info-container ${
                                    highlightedMessageId === item.id
                                      ? "highlighted"
                                      : ""
                                  } ${
                                    item.messageType === "poll"
                                      ? "ir-chat-conv-info-container-poll"
                                      : ""
                                  }`}
                                  //  ref={refs.current[item.chatId]}
                                  ref={chatMessageRefs.current[item.chatId]}
                                >
                                  <div
                                    className={`ir-chat-conv-info-inner-container  ${
                                      highlightedMessageId === item.id
                                        ? "highlighted"
                                        : ""
                                    } ${
                                      item.messageType === "poll"
                                        ? "ir-chat-conv-info-container-poll"
                                        : ""
                                    }`}
                                  >
                                    <div className="ir-chat-conv-user-details">
                                      <div className="ir-chat-conv-name">
                                        {item.userName || "Deleted User"}
                                      </div>
                                      <div className="ir-chat-conv-time">
                                        {messageTime || item?.displayTime}
                                        {profile?.firstname === "admin" &&
                                          profile?.lastname === "admin" && (
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "10px",
                                              }}
                                            >
                                              {/* Download Icon */}
                                              {(item?.messageType === "video" ||
                                                item?.messageType ===
                                                  "image") && (
                                                <span
                                                  className="ir-chat-content-download-icon bi bi-download"
                                                  onClick={() =>
                                                    handleDownload(item)
                                                  }
                                                  style={{ cursor: "pointer" }}
                                                ></span>
                                              )}
                                            </div>
                                          )}

                                        {/* Delete Icon */}
                                        {/* {(item?.userName === profile?.username ||
                                    (profile?.firstname === "admin" && profile?.lastname === "admin")) && (
                                        <span
                                        className="ir-chat-content-delete-icon material-symbols-outlined"
                                        onClick={() => handleModalToggle(item)}
                                        style={{ cursor: "pointer" }}
                                        >
                                        delete
                                        </span>
                                    )} */}

                                        {/* Menu Section */}
                                        <div
                                          className="ir-chat-menu-container"
                                          ref={messageMenuRef}
                                        >
                                          <button
                                            className="ir-chat-menu-button"
                                            onClick={() =>
                                              toggleMessageMenu(item.chatId)
                                            }
                                          >
                                            <i class="bi bi-three-dots"></i>
                                          </button>

                                          {showMessageMenu === item.chatId && (
                                            <div
                                              ref={messageMenuRef}
                                              className={`ir-chat-menu-options ${
                                                profile.username ===
                                                item.userName
                                                  ? "sender-menu"
                                                  : "receiver-menu"
                                              }`}
                                            >
                                              <button
                                                className="ir-chat-menu-option"
                                                //   onClick={() =>
                                                //     toggleEmojiPicker(item.chatId)
                                                //   }
                                                onClick={(e) =>
                                                  toggleEmojiPicker(
                                                    item.chatId,
                                                    e.currentTarget,
                                                    profile.username ===
                                                      item.userName
                                                  )
                                                }
                                              >
                                                React
                                              </button>

                                              <button
                                                className="ir-chat-menu-option"
                                                onClick={() =>
                                                  handleReplyClick(item)
                                                }
                                              >
                                                Reply
                                              </button>

                                              {(item?.userName ===
                                                profile?.username ||
                                                (profile?.firstname ===
                                                  "admin" &&
                                                  profile?.lastname ===
                                                    "admin")) && (
                                                <button
                                                  className="ir-chat-menu-option"
                                                  onClick={() =>
                                                    handleModalToggle(item)
                                                  }
                                                  style={{ cursor: "pointer" }}
                                                >
                                                  Delete
                                                </button>
                                              )}
                                            </div>
                                          )}

                                          {showEmojiPicker === item.chatId && (
                                            <div
                                              ref={messageMenuRef}
                                              // className={`ir-chat-emoji-picker ${
                                              //   profile.username === item.userName
                                              //     ? "sender-picker"
                                              //     : "receiver-picker"
                                              // }`}
                                              className={`ir-chat-emoji-picker ${
                                                profile.username ===
                                                item.userName
                                                  ? "sender-picker"
                                                  : "receiver-picker"
                                              } ${emojiPickerPosition}`}
                                            >
                                              {/* <EmojiPicker
                                            reactions={['👍', '❤️', '😂', '😮', '😢', '😡']}
                                            searchDisabled={false}
                                            skinTonesDisabled={false}
                                              onEmojiClick={(
                                                event,
                                                emojiObject
                                              ) =>
                                                handleEmojiSelect(
                                                  item,
                                                  emojiObject,
                                                  `event`
                                                )
                                              }
                                            /> */}

                                              <EmojiPicker
                                                reactions={[
                                                  "👍",
                                                  "❤️",
                                                  "😂",
                                                  "😮",
                                                  "😢",
                                                  "😡",
                                                ]}
                                                // searchDisabled={true}
                                                skinTonesDisabled={true}
                                                onEmojiClick={(
                                                  event,
                                                  emojiObject
                                                ) =>
                                                  handleEmojiSelect(
                                                    item,
                                                    emojiObject,
                                                    event
                                                  )
                                                }
                                              />
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    {item?.isReply && (
                                      <div className="reply-preview bg-trns">
                                        <div className="reply-content">
                                          <p>
                                            <strong>
                                              {item?.replyTo.userName}
                                            </strong>
                                          </p>
                                          {/* Use AssetRender to handle message types */}
                                          <MiniAssetRender
                                            data={item?.replyTo}
                                            // imageClicked={(image) => console.log('Image clicked:', image)}
                                            options={{
                                              width: 50,
                                              height: 50,
                                              videoControl: false,
                                            }}
                                          />
                                        </div>
                                      </div>
                                    )}

                                    <div className="user-chat-content">
                                      <div className="ctext-content">
                                        <AssetRender
                                          imageClicked={imageClicked}
                                          data={item}
                                          options={{
                                            videoControl: false,
                                            height: 80,
                                          }}
                                        />
                                      </div>

                                      {/* Reactions Section */}
                                      {item.reactions &&
                                        item.reactions.length > 0 && (
                                          <div
                                            className="reaction-container"
                                            onClick={() =>
                                              openReactionsPopup(
                                                item.chatId,
                                                item.reactions
                                              )
                                            }
                                          >
                                            {item?.reactions?.map(
                                              (reaction, index) => (
                                                <span
                                                  key={index}
                                                  className="reaction-item"
                                                  title={reaction.userName}
                                                  // onClick={() => openReactionsPopup(reaction)}
                                                >
                                                  {reaction.emoji}
                                                </span>
                                              )
                                            )}
                                          </div>
                                        )}

                                      {showReactionsPopup &&
                                        currentPopupChatId === item.chatId && (
                                          <div
                                            className={`reactions-popup  ${
                                              profile.username === item.userName
                                                ? "sender-reaction-con"
                                                : "receiver-reaction-con"
                                            }`}
                                          >
                                            <div className="popup-header">
                                              <div className="popup-filters">
                                                {/* "All" Button */}
                                                <button
                                                  className={`filter-button ${
                                                    selectedEmoji === "All"
                                                      ? "active"
                                                      : ""
                                                  }`}
                                                  onClick={() =>
                                                    setSelectedEmoji("All")
                                                  }
                                                >
                                                  All
                                                </button>
                                                {/* Unique Emoji Buttons */}
                                                {uniqueEmojis.map(
                                                  (emoji, index) => (
                                                    <button
                                                      key={index}
                                                      className={`filter-button ${
                                                        selectedEmoji === emoji
                                                          ? "active"
                                                          : ""
                                                      }`}
                                                      onClick={() =>
                                                        setSelectedEmoji(emoji)
                                                      }
                                                    >
                                                      {emoji}
                                                    </button>
                                                  )
                                                )}
                                              </div>
                                              <button
                                                onClick={closeReactionsPopup}
                                                className="all-reaction-close-btn"
                                              >
                                                &times;
                                              </button>
                                            </div>

                                            {/* Filter Buttons */}

                                            {/* Reactions Content */}
                                            <div className="popup-content">
                                              {reactionsData
                                                .filter(
                                                  (reaction) =>
                                                    selectedEmoji === "All" ||
                                                    reaction.emoji ===
                                                      selectedEmoji
                                                )
                                                .map((reaction, index) => (
                                                  <div
                                                    key={index}
                                                    className="reaction-detail"
                                                  >
                                                    <img
                                                      src={
                                                        reaction?.profileImage ||
                                                        NoImage
                                                      }
                                                      alt={
                                                        reaction?.userName ||
                                                        "User"
                                                      }
                                                      className="reaction-user-image"
                                                    />

                                                    <span>
                                                      {reaction.userName}
                                                    </span>
                                                  </div>
                                                ))}
                                            </div>
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                          </li>
                        </React.Fragment>
                      );
                    })}
                <li>
                  <div ref={scrollTarget} className="ir-scroll-target"></div>
                </li>
              </ul>
            </div>
          </div>

          <div className="ir-chat-conversation-container">
            {activeChatGroupData?.freeze ? (
              <div className="ir-chat-frozen-message">
                <p>
                  This group is currently inactive. Please reach out to the
                  admin to activate it.
                </p>
                {userStore.profile.username === "admin" && (
                  <button
                    className="group_unfreeze_btn"
                    onClick={() => unFreezeRoom(activeChatGroupData.id)}
                  >
                    Activate Group
                  </button>
                )}
              </div>
            ) : (
              <div className="ir-chat-conversation-send-container">
                <form
                  className="app-content-footer d-flex"
                  onSubmit={handleSubmit}
                  disabled={activeChatGroupData?.freeze}
                >
                  {/* Reply Preview */}
                  {replyingMessage && (
                    <div className="reply-preview">
                      <div className="reply-content">
                        <p>
                          Replying to:{" "}
                          <strong>{replyingMessage.userName}</strong>
                        </p>
                        {/* Use AssetRender to handle message types */}
                        <MiniAssetRender
                          data={replyingMessage}
                          // imageClicked={(image) => console.log('Image clicked:', image)}
                          options={{
                            width: 50,
                            height: 50,
                            videoControl: false,
                          }}
                        />
                      </div>
                      <button
                        type="button"
                        className="cancel-reply"
                        onClick={cancelReply}
                      >
                        <i className="bi bi-x-lg"></i>
                      </button>
                    </div>
                  )}

                  <div className="input-wrapper">
                    <div className={`app-content-footer-buttons`}>
                      <div
                        data-bs-toggle="tooltip"
                        className={`app-footer-plus`}
                        // onClick={addAttachHandle}
                        onClick={(e) => {
                          if (activeChatGroupData?.freeze) {
                            e.preventDefault();
                          } else {
                            addAttachHandle();
                          }
                        }}
                        title="Upload"
                        // disabled={activeChatGroupData?.freeze}
                      >
                        <i className="bi bi-paperclip"></i>
                      </div>
                    </div>
                    {/* {addAttachActive && ( */}
                    <div
                      className={`ir-ws-add-attachment-main-container ${
                        addAttachActive ? "addActiveOpen" : ""
                      } `}
                    >
                      <div className="add-attach-menu-item">
                        <label htmlFor="sendAattachDocs">
                          <i className="bi bi-file-earmark"></i>
                          <input
                            id="sendAattachDocs"
                            type="file"
                            accept="*"
                            // Spread the props for file input functionality
                            {...getInputProps()}
                            onClick={(e) => {
                              e.stopPropagation();
                              e.target.value = null;
                            }}
                            onChange={sendAttachDocsHandle}
                          />
                          Documents
                        </label>
                      </div>
                      <div className="add-attach-menu-item">
                        <label htmlFor="sendAttachImage">
                          <i className="bi bi-image"></i>
                          <input
                            id="sendAttachImage"
                            type="file"
                            onChange={sendAttachImageHandle}
                            multiple
                            accept="image/*"
                            onClick={(e) => {
                              e.target.value = null;
                            }}
                          />
                          Photos
                        </label>
                      </div>
                      <div className="add-attach-menu-item">
                        <label htmlFor="sendAttachVideo">
                          <i className="bi bi-play-btn"></i>
                          <input
                            id="sendAttachVideo"
                            type="file"
                            onChange={sendAttachImageHandle}
                            accept="video/*"
                            onClick={(e) => {
                              e.target.value = null;
                            }}
                          />
                          Videos
                        </label>
                      </div>
                      <div
                        className="add-attach-menu-item"
                        // onClick={() => setOpenCamModal(true)}
                        onClick={() => {
                          if (!replyingMessage) {
                            setOpenCamModal(true);
                          }
                        }}
                        style={{
                          cursor: replyingMessage ? "not-allowed" : "pointer",
                        }}
                      >
                        <i className="bi bi-camera-video"></i> Record Video
                      </div>
                      {/* <div
                        className="add-attach-menu-item"
                        onClick={() => setOpenAudioModal(true)}
                    >
                        <i className="bi bi-camera-video"></i> Voice Record
                    </div> */}
                      <div
                        className="add-attach-menu-item"
                        onClick={openCanvasModal}
                      >
                        <i className="bi bi-easel-fill"></i> Whiteboard
                      </div>
                      <div
                        className="add-attach-menu-item"
                        // onClick={togglePollPopup}
                        onClick={() => {
                          if (!replyingMessage) {
                            togglePollPopup();
                          }
                        }}
                        style={{
                          cursor: replyingMessage ? "not-allowed" : "pointer",
                        }}
                      >
                        <i className="bi bi-bar-chart"></i> Create Poll
                      </div>
                      <div
                        className="add-attach-menu-item"
                        // onClick={toggleQuestionPopupVisibility}
                        onClick={() => {
                          if (!replyingMessage) {
                            toggleQuestionPopupVisibility();
                          }
                        }}
                        style={{
                          cursor: replyingMessage ? "not-allowed" : "pointer",
                        }}
                      >
                        {/* <i className="bi bi-bar-chart"></i> Create Question
                         */}
                        <i className="bi bi-question-circle"></i> Create
                        Question
                      </div>
                    </div>
                    <textarea
                      style={textareaStyle}
                      className="form-control chat-text-area"
                      placeholder="Message"
                      value={chatMessage || ""}
                      onChange={handleChatSendMessage}
                      onKeyPress={handleEnterKeyPress}
                      disabled={activeChatGroupData?.freeze}
                      ref={inputRef}
                    ></textarea>
                    {/*
                         <input
                                    className="btn bg-app-default-color app-content-footer-send custom-checkbox"
                                    type="checkbox"
                                    name="answerPrivacy"
                                    checked={answerPrivacy}
                                    onChange={(e) => setAnswerPrivacy(e.target.checked)}
                                /> */}
                    {showPrivacyOptions && (
                      <div
                        className="privacy-options-dropdown position-absolute"
                        ref={privacyControlRef}
                        style={{
                          bottom: "60px",
                          right: "58px",
                          background: "#cdbfff",
                          borderRadius: "8px",
                          padding: "10px",
                          zIndex: 10,
                          width: "150px",
                        }}
                      >
                        <label
                          style={{
                            display: "flex",
                            alignItems: "spaceBetween",
                            gap: "60px",
                            fontSize: "14px",
                            color: "white",
                            fontWeight: "500",
                          }}
                        >
                          <div
                            className={`custom-checkbox ans-privacy-checkbox ${
                              answerPrivacy ? "checked" : ""
                            }`}
                            onClick={() => setAnswerPrivacy(!answerPrivacy)}
                            style={{
                              width: "20px",
                              height: "20px",
                              border: "2px solid #ccc",
                              borderRadius: "4px",
                              background: answerPrivacy ? "#6a5acd" : "white",
                              cursor: "pointer",
                              // marginRight:"34px"
                            }}
                          />
                          Privacy
                        </label>
                      </div>
                    )}

                    <button
                      className="btn bg-app-default-color app-content-footer-send w-60"
                      disabled={!chatMessage}
                      type="submit"
                      style={{}}
                    >
                      {/* <i className="bi bi-send"></i> */}
                      send
                      <span
                        className="answer-privacy-toggle-icon"
                        onClick={(e) => togglePrivacyOptions(e)}
                      >
                        {showPrivacyOptions ? (
                          <i className="bi bi-chevron-down"></i>
                        ) : (
                          <i className="bi bi-chevron-up"></i>
                        )}
                      </span>
                    </button>

                    <button
                      className="btn bg-app-default-color app-content-footer-voice"
                      //   disabled={!chatMessage}
                      type="button"
                      onClick={() => setOpenAudioModal(true)}
                      disabled={activeChatGroupData?.freeze}
                    >
                      <i className="bi bi-mic"></i>
                    </button>
                  </div>
                </form>
              </div>
            )}
          </div>

          {shareModal && (
            <div className="ir-ws-position-fixed ir-ws-sign-popup-container ir-ws-share-main-container">
              <div className="ir-ws-position-absolute ir-bg-white ir-ws-sign-popup-inner-container">
                <div className="iir-ws-signup-content-container">
                  <div
                    className="ir-ws-position-absolute ir-ws-signup-close-container"
                    onClick={shareCloseModalHandle}
                  >
                    <i className="bi bi-x-lg"></i>
                  </div>
                  <div className="ir-ws-signup-content-inner-container">
                    <h3 className="ir-ws-text-center ir-ws-heading-default-color ir-ws-signup-content-title">
                      Share Case
                    </h3>
                  </div>
                  <div className="ir-share-modal-inside-content">
                    <p>Invite your friends through social network</p>
                    <div className="ir-share-container">
                      <div className="ir-share-icon-container ir-share-icon-container-hover">
                        <FacebookShareButton
                          url={shareUrl}
                          quote={"Dummy text!"}
                          hashtag="#ir4u"
                        >
                          <FacebookIcon size={50} round />
                        </FacebookShareButton>
                      </div>
                      <div className="ir-share-icon-container ir-share-icon-container-hover">
                        <WhatsappShareButton
                          url={shareUrl}
                          quote={"Dummy text!"}
                        >
                          <WhatsappIcon size={50} round />
                        </WhatsappShareButton>
                      </div>
                      <div className="ir-share-icon-container ir-share-icon-container-hover">
                        {!tooltipText ? (
                          <div
                            className="ir-share-icon-copy-link ir-share-icon-link"
                            onClick={handleCopyLink}
                            title="Copy Link"
                          >
                            <i className="bi bi-link-45deg"></i>
                          </div>
                        ) : (
                          <div
                            className="ir-share-icon-copy-link ir-share-icon-link"
                            title="Link Copied"
                          >
                            <i class="bi bi-check-lg"></i>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* {openCamModal && (
            <div className="ir-ws-position-fixed ir-ws-sign-popup-container">
              <div className="ir-ws-position-absolute ir-bg-white ir-ws-sign-popup-inner-container">
                <div className="ir-ws-signup-content-container">
                  <div
                    className="ir-ws-position-absolute ir-ws-signup-close-container"
                    onClick={camCloseModalHandle}
                  >
                    <i className="bi bi-x-lg"></i>
                  </div>
                  <div className="ir-ws-signup-content-inner-container">
                    <h3 className="ir-ws-text-center ir-ws-heading-default-color ir-ws-signup-content-title">
                      Record Video
                    </h3>
                  </div>
                  <div className="ir-ws-signup-form-container ir-ws-chat-recorded-video">
                    <div className="ir-share-container">
                      <div className="audio-controls">
                      <video
                        ref={videoRecordRef}
                        style={{ width: "100%",  display: permissionGranted ? "block" : "none" }}
                    />
                      </div>

                    </div>
                    <div className="ir-modal-btn-container record-btn-container">
                    {!permissionGranted && recordedVideoURL ===null &&(
                        <div className="ir-ws-text-center ir-ws-default-btn-container">
                          <button
                            className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn"
                            onClick={getCameraPermission}
                          >
                            <span>Get Permissions</span>
                          </button>
                        </div>
                      )}
                      {permissionGranted && !recording && (
                    <div className="ir-ws-text-center ir-ws-default-btn-container">
                      <button
                        className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn"
                        onClick={startRecording}
                      >
                        <span>Start Recording</span>
                      </button>
                    </div>
                  )}
                  {permissionGranted && recording && (
                    <div className="ir-ws-text-center ir-ws-default-btn-container">
                      <button
                        className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn"
                        onClick={stopRecording}
                      >
                        <span>Stop Recording</span>
                      </button>
                    </div>
                  )}
                  {recordedVideoURL && (


                    <video
                      src={recordedVideoURL}
                      controls
                      style={{ width: "100%",  }}
                    />

                )}
                { recordedVideoURL !==null &&(
                        <div className="ir-ws-text-center ir-ws-default-btn-container">
                          <button
                            className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn"
                            onClick={submitVideo}

                          >
                            <span>Upload Video</span>
                          </button>
                        </div>
                      )}

                    </div>
                  </div>
                </div>
              </div>
            </div>
          )} */}

          {openCamModal && (
            <div className="ir-ws-position-fixed ir-ws-sign-popup-container">
              <div className="ir-ws-position-absolute ir-bg-white ir-ws-sign-popup-inner-container">
                <div className="ir-ws-signup-content-container">
                  <div
                    className="ir-ws-position-absolute ir-ws-signup-close-container"
                    onClick={camCloseModalHandle}
                  >
                    <i className="bi bi-x-lg"></i>
                  </div>
                  <div className="ir-ws-signup-content-inner-container">
                    {!permissionGranted && recordedVideoURL === null && (
                      <h3 className="ir-ws-text-center ir-ws-heading-default-color ir-ws-signup-content-title">
                        Allow video to record
                      </h3>
                    )}
                  </div>
                  <div className="ir-ws-signup-form-container ir-ws-chat-recorded-video">
                    <div className="ir-share-container">
                      <div className="ir-chat-recorded-video-cont">
                        <video
                          ref={videoRecordRef}
                          style={{
                            display: permissionGranted ? "block" : "none",
                          }}
                        />
                      </div>
                      {permissionGranted && recording && (
                        <p className="ir-chat-record-time-status">
                          {formatTimeRecord(recordingTime)}
                        </p>
                      )}
                      {permissionGranted && recording && (
                        <div class="recording-container">
                          <div class="recording-button">
                            <div class="blinking-dot"></div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="ir-modal-btn-container record-btn-container ir-chat-recorded-video-cont">
                      {!permissionGranted && recordedVideoURL === null && (
                        <div className="ir-ws-text-center ir-ws-default-btn-container">
                          <button
                            className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn"
                            onClick={getCameraPermission}
                          >
                            <span>Allow</span>
                          </button>
                        </div>
                      )}
                      {permissionGranted && !recording && (
                        <div className="ir-ws-text-center ir-ws-default-btn-container">
                          <button
                            className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn"
                            onClick={startRecording}
                          >
                            <span>Record Video</span>
                          </button>
                        </div>
                      )}
                      {permissionGranted && recording && (
                        <div className="ir-ws-text-center ir-ws-default-btn-container">
                          <button
                            className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn"
                            onClick={stopRecording}
                          >
                            <span>Stop</span>
                          </button>
                        </div>
                      )}
                      {recordedVideoURL && (
                        <video
                          src={recordedVideoURL}
                          controls
                          // style={{ width: "100%",  }}
                        />
                      )}
                      {recordedVideoURL !== null && (
                        <div className="ir-ws-text-center ir-ws-default-btn-container">
                          <button
                            className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn"
                            onClick={submitVideo}
                          >
                            <span>Send</span>
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Modal for Audio Recording */}

          {openAudioModal && (
            <div className="ir-ws-position-fixed ir-ws-sign-popup-container">
              <div className="ir-ws-position-absolute ir-bg-white ir-ws-sign-popup-inner-container">
                <div className="ir-ws-signup-content-container">
                  <div
                    className="ir-ws-position-absolute ir-ws-signup-close-container"
                    onClick={audioCloseModalHandle}
                  >
                    <i className="bi bi-x-lg"></i>
                  </div>
                  <div className="ir-ws-signup-content-inner-container">
                    <h3 className="ir-ws-text-center ir-ws-heading-default-color ir-ws-signup-content-title">
                      Record Audio
                    </h3>
                  </div>
                  <div className="ir-ws-signup-form-container">
                    <div className="ir-share-container">
                      <div className="audio-controls">
                        <audio
                          className="ir-ws-width-100 ir-ws-audio-upload"
                          style={{
                            display:
                              audioRecordingStatus === "recording"
                                ? "block"
                                : "none",
                          }}
                          ref={liveAudioFeedRef}
                        ></audio>
                      </div>

                      {/* Recording wave animation */}
                      <div
                        className={`recording-wave-container ${
                          audioRecordingStatus === "recording"
                            ? "recording-active"
                            : "recording-inactive"
                        }`}
                      >
                        <div className="recording-wave">
                          <div className="wave-bar" style={{ "--i": 1 }}></div>
                          <div className="wave-bar" style={{ "--i": 2 }}></div>
                          <div className="wave-bar" style={{ "--i": 3 }}></div>
                          <div className="wave-bar" style={{ "--i": 4 }}></div>
                          <div className="wave-bar" style={{ "--i": 5 }}></div>
                          <div className="wave-bar" style={{ "--i": 6 }}></div>
                          <div className="wave-bar" style={{ "--i": 7 }}></div>
                          <div className="wave-bar" style={{ "--i": 8 }}></div>
                        </div>
                      </div>

                      {recordedAudio ? (
                        <div className="audio-player">
                          <audio
                            className="ir-ws-width-100 ir-ws-audio-upload"
                            src={recordedAudio}
                            controls
                          ></audio>
                        </div>
                      ) : null}
                    </div>
                    <div className="ir-modal-btn-container record-btn-container">
                      {!audioPermission && !recordedAudio ? (
                        <div className="ir-ws-text-center ir-ws-default-btn-container">
                          <button
                            className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn"
                            onClick={getAudioPermission}
                          >
                            <span>Get Permissions</span>
                          </button>
                        </div>
                      ) : null}

                      {audioPermission &&
                      audioRecordingStatus === "inactive" ? (
                        <div className="ir-ws-text-center ir-ws-default-btn-container">
                          <button
                            className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn"
                            onClick={startAudioRecording}
                          >
                            <span>Start Recording</span>
                          </button>
                        </div>
                      ) : null}

                      {audioRecordingStatus === "recording" ? (
                        <div className="ir-ws-text-center ir-ws-default-btn-container">
                          <button
                            className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn"
                            onClick={stopAudioRecording}
                          >
                            <span>Stop Recording</span>
                          </button>
                        </div>
                      ) : null}

                      {recordedAudio ? (
                        <div className="ans-privacy-upoadtn-con">
                          <div className="ir-ws-text-center ir-ws-default-btn-container">
                            <button
                              className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn"
                              onClick={submitAudio}
                            >
                              <span>Upload Recording</span>
                            </button>
                          </div>
                          {/* <div className="create-question__checkbox-container">
                    <label className="create-question__checkbox-label">
                    <input
                        className="create-question__checkbox"
                        type="checkbox"
                        name="answerPrivacy"
                        checked={answerPrivacy}
                        onChange={(e) => setAnswerPrivacy(e.target.checked)}
                    />
                    Answer Privacy
                    </label>
                    </div> */}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* {openWhiteBoardModal && (
            <div className="ir-whiteboard-modal">
              <div className="ir-whiteboard-modal-container">
                <div className="ir-whiteboard-modal-content">
                  <div className="ir-form-title">
                    <button onClick={whiteBoardCloseModalHandle}>
                      <span className="material-symbols-outlined">
                        arrow_back
                      </span>
                    </button>
                    <h2>Whiteboard</h2>
                  </div>
                  <div className="ir-whiteboard-modal-inside-content">
                    <div className="ir-share-container">
                      <WhiteBoard
                        isUpload={isUpload}
                        updateIsUpload={(data) => setIsUpload(data)}
                        uploadFile={(file) => whiteBoardSubmit(file)}
                        closeModel={setOpenWhiteBoardModal}
                        submitImage={submitImage}
                        answerPrivacy={answerPrivacy}
                        setAnswerPrivacy={setAnswerPrivacy}
                      />
                      <div className="ir-modal-btn-container ir-wbd-upload-btn-container">
                        <button
                          className="btn btn-submit"
                          onClick={() => {
                            setIsUpload(true);
                          }}
                        >
                          <span className="material-symbols-outlined">
                            done
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )} */}

          {openWhiteBoardModal && (
            <div className="ir-ws-position-fixed ir-ws-sign-popup-container">
              <div className="ir-ws-position-absolute ir-bg-white ir-ws-sign-popup-inner-container ir-ws-chat-upload-img-modal-container ir-ws-wb-upload-modal">
                <div className="iks-mint-modal-content">
                  <div
                    className="ir-ws-position-absolute ir-ws-signup-close-container"
                    onClick={whiteBoardCloseModalHandle}
                  >
                    <i className="bi bi-x-lg"></i>
                  </div>
                  <div className="ir-ws-signup-content-title-container">
                    <h3 className="ir-ws-text-center ir-ws-heading-default-color ir-ws-signup-content-title">
                      WhiteBoard
                    </h3>
                  </div>
                  <div className="ir-ws-signup-content-inner-container">
                    <WhiteBoard
                      isUpload={isUpload}
                      updateIsUpload={(data) => setIsUpload(data)}
                      uploadFile={(file) => whiteBoardSubmit(file)}
                      closeModel={setOpenWhiteBoardModal}
                      submitImage={submitImage}
                      answerPrivacy={answerPrivacy}
                      setAnswerPrivacy={setAnswerPrivacy}
                    />
                    <div className="ir-modal-btn-container ir-wbd-upload-btn-container">
                      <button
                        className="btn btn-submit"
                        onClick={() => {
                          setIsUpload(true);
                        }}
                      >
                        <span className="material-symbols-outlined">done</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {openImageModal && (
            <div className="ir-ws-position-fixed ir-ws-sign-popup-container">
              <div className="ir-ws-position-absolute ir-bg-white ir-ws-sign-popup-inner-container ir-ws-chat-upload-img-modal-container ir-ws-edit-img-upload-modal">
                <div className="iks-mint-modal-content">
                  <div
                    className="ir-ws-position-absolute ir-ws-signup-close-container"
                    onClick={imageCloseModalHandle}
                  >
                    <i className="bi bi-x-lg"></i>
                  </div>
                  <div className="ir-ws-signup-content-title-container">
                    <h3 className="ir-ws-text-center ir-ws-heading-default-color ir-ws-signup-content-title">
                      Upload Image
                    </h3>
                  </div>
                  {!multipleImageModel ? (
                    <div className="ir-ws-signup-content-inner-container">
                      <div className="ir-share-container-image-modal">
                        <div className="ir-chat-content-multi-img-main-cont">
                          <div className="ir-chat-selected-multi-img-cont">
                            <img
                              src={selectedMultiImage?.imageFile}
                              className="ir-chat-selected-multi-img"
                              alt="Selected"
                            />
                          </div>
                          <div className="ir-chat-multiple-image-cont">
                            {multipleImage.map((item) => (
                              <div
                                className={`ir-chat-mulit-image-box ${
                                  selectedMultiImage.id === item.id
                                    ? "ir-chat-multi-active-img"
                                    : "ir-chat-multi-inactive-img"
                                }`}
                                key={item.id}
                                onClick={() => setSelectedMultiImage(item)}
                              >
                                <img src={item.imageFile} alt="edited img" />
                                <div className="ir-ws-chat-multi-image-func">
                                  <i
                                    class="bi bi-pencil"
                                    onClick={() => handleEditImage(item)}
                                  ></i>
                                  <i
                                    class="bi bi-trash"
                                    onClick={() => deleteImage(item.id)}
                                  ></i>
                                </div>
                              </div>
                            ))}
                          </div>
                          <div className="ir-chat-cont-img-input-cont">
                            <input
                              type="text"
                              className="ir-ws-clinical-note-input-field"
                              placeholder="Add clinical note"
                              value={selectedMultiImage?.message} // Display the message for the selected image
                              onChange={handleImageMessageChange} // Handle message change
                            />
                            <p
                              className="ir-chat-cont-msg-send-btn"
                              onClick={sendEditedImgMsg}
                            >
                              {sendingImg ? "Sending..." : "Send"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="ir-chat-edit-container-image-modal">
                      <FilerobotImageEditor
                        source={selectedImageEdit?.imageFile}
                        Text={{
                          text: "Double click to add text ",
                          fontSize: 22,
                        }}
                        onBeforeSave={(imageFileInfo) => {
                          console.log("Image info before save:", imageFileInfo);
                          return false; // Prevents the modal from appearing
                        }}
                        // onSave={(editedImageObject) => {
                        //   console.log(editedImageObject);
                        //   // const editedImageURL = editedImageObject.imageBase64 || editedImageObject.imageFile; // Edited image in base64 or blob URL

                        //   setMultipleImage((prevImages) =>
                        //     prevImages.map((image) =>
                        //       image.id === selectedImageEdit.id
                        //         ? {
                        //             ...image,
                        //             imageFile: editedImageObject.imageBase64,
                        //           } // Replace the imageFile for the matching id
                        //         : image
                        //     )
                        //   );

                        //   setMultipleImageModel(false);
                        //   // setMultipleImageModel(false);
                        // }}
                        onSave={(editedImageObject) => {
                          console.log(editedImageObject);
                          // Update the multipleImage array
                          const updatedImages = multipleImage.map((image) =>
                            image.id === selectedImageEdit.id
                              ? {
                                  ...image,
                                  imageFile: editedImageObject.imageBase64,
                                } // Replace the imageFile for the matching id
                              : image
                          );
                          setMultipleImage(updatedImages);

                          // Update the selectedMultiImage to reflect the edited image
                          const updatedSelectedImage = {
                            ...selectedMultiImage,
                            imageFile: editedImageObject.imageBase64,
                          };
                          setSelectedMultiImage(updatedSelectedImage);

                          setMultipleImageModel(false);
                        }}
                        onClose={() => setMultipleImageModel(false)}
                        defaultSavedImageType="jpeg" // Always save as JPEG
                        defaultSavedImageQuality={1} // Set quality to 80%
                        Pen={{
                          selectAnnotationAfterDrawing: true,
                        }}
                        tabsIds={[TABS.ADJUST, TABS.ANNOTATE]}
                        Rotate={{ angle: 90, componentType: "slider" }}
                        Crop={{ noPresets: true, ratio: "custom" }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {openVideoModal && (
            <div className="ir-ws-position-fixed ir-ws-sign-popup-container">
              <div className="ir-ws-position-absolute ir-bg-white ir-ws-sign-popup-inner-container ir-ws-chat-upload-img-modal-container">
                <div className="iks-mint-modal-content">
                  <div
                    className="ir-ws-position-absolute ir-ws-signup-close-container"
                    onClick={imageCloseModalHandle}
                  >
                    <i className="bi bi-x-lg"></i>
                  </div>
                  <div className="ir-ws-signup-content-title-container">
                    <h3 className="ir-ws-text-center ir-ws-heading-default-color ir-ws-signup-content-title ir-ws-upload-video-text">
                      Upload Video
                    </h3>
                  </div>
                  <div className="ir-ws-signup-content-inner-container">
                    <div className="ir-share-container">
                      {/* Video Handling */}
                      {convertedVideoURL === null ? (
                        <Progressbar progress={progress} />
                      ) : (
                        <>
                          {selectedImage[0]?.type.startsWith("video/") && (
                            <div className="ir-ws-video-trim-container">
                              {isTrimming ? (
                                <div className="ir-ws-video-trim-wrapper">
                                  <video
                                    id="videoElement"
                                    className="video-js ir-ws-video-preview"
                                    controls
                                    onLoadedMetadata={(e) => {
                                      setVideoDuration(e.target.duration);
                                      setTrimRange([0, e.target.duration]);
                                    }}
                                    src={convertedVideoURL}
                                  />
                                  <div className="ir-ws-trim-slider-container">
                                    <Slider
                                      range
                                      min={0}
                                      max={
                                        videoDuration > 0 ? videoDuration : 1
                                      }
                                      step={videoDuration > 60 ? 0.1 : 0.01}
                                      value={trimRange}
                                      onChange={(values) => {
                                        const newStart = parseFloat(values[0]);
                                        const newEnd = parseFloat(values[1]);
                                        setTrimRange([newStart, newEnd]);
                                        updateVideoPreview(newStart, newEnd);
                                      }}
                                      onAfterChange={(values) => {
                                        const newStart = parseFloat(values[0]);
                                        const newEnd = parseFloat(values[1]);
                                        setTrimRange([newStart, newEnd]);
                                      }}
                                    />
                                    <div className="ir-ws-slider-labels">
                                      <span>
                                        Start: {formatTime(trimRange[0])}
                                      </span>
                                      <span>
                                        End: {formatTime(trimRange[1])}
                                      </span>
                                    </div>
                                  </div>
                                  <p className="ir-ws-trim-title">
                                    Trim your video before uploading
                                  </p>
                                  <div className="ir-ws-trim-button-container">
                                    <button
                                      className="ir-ws-app-bg ir-color-white ir-ws-no-border ir-ws-trim-btn"
                                      onClick={trimVideo}
                                      disabled={
                                        isTrimmedVideoConverted === false
                                      }
                                    >
                                      {isTrimmedVideoConverted === false ? (
                                        <span>
                                          <i className="bi bi-hourglass-split"></i>{" "}
                                          Trimming...
                                        </span>
                                      ) : (
                                        <span>Trim Video</span>
                                      )}
                                    </button>
                                  </div>
                                </div>
                              ) : (
                                <div
                                  className="ir-ws-video-wrapper"
                                  ref={videoContainerRef}
                                >
                                  <video
                                    className="ir-ws-video-preview"
                                    controls
                                    src={convertedVideoURL}
                                    ref={videoCropRef}
                                    onLoadedMetadata={onVideoLoaded}
                                  />

                                  {cropVideoOn && (
                                    <Rnd
                                      bounds="parent"
                                      position={{
                                        x: videoCrop.x,
                                        y: videoCrop.y,
                                      }}
                                      size={{
                                        width: videoCrop.width,
                                        height: videoCrop.height,
                                      }}
                                      minWidth={100}
                                      minHeight={100}
                                      onDragStop={(e, { x, y }) =>
                                        setVideoCrop((prev) => ({
                                          ...prev,
                                          x,
                                          y,
                                        }))
                                      }
                                      onResizeStop={(
                                        e,
                                        direction,
                                        ref,
                                        delta,
                                        position
                                      ) => {
                                        setVideoCrop({
                                          x: position.x,
                                          y: position.y,
                                          width: ref.offsetWidth,
                                          height: ref.offsetHeight,
                                        });
                                      }}
                                      style={{
                                        border: "3px solid #e9ecef",
                                        background: "rgba(0, 0, 0, 0.15)",
                                        boxSizing: "border-box",
                                      }}
                                      // lockAspectRatio={true}
                                    />
                                  )}
                                </div>
                              )}
                            </div>
                          )}
                        </>
                      )}
                      {uploaded && (
                        <div className="progress mt-2">
                          <div
                            className="progress-bar"
                            role="progressbar"
                            aria-valuenow={uploaded}
                            aria-valuemin="0"
                            aria-valuemax="100"
                            style={{ width: `${uploaded}%` }}
                          >
                            {`${uploaded}%`}
                          </div>
                        </div>
                      )}
                      {convertedVideoURL !== null && !isTrimming && (
                        <>
                          {/* <button 
            onClick={handleCrop} 
            disabled={videoCropProcessing}
            style={{ marginTop: '10px' }}
          >
            {videoCropProcessing ? 'Processing...' : 'Crop Video'}
          </button> */}
                          {/* <div className="ir-ws-text-center ir-ws-default-btn-container trim-upload-btn">
                            <button
                              className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn"
                              // disabled={disableUploadBtn}
                              onClick={()=>setCropVideoOn(true)}
                            >
                              <span>Crop</span>
                            </button>
                          </div> */}
                          <div
                            className="ir-chat-video-crop-cont"
                            title="Crop"
                            onClick={() => {
                              setCropVideoOn(!cropVideoOn);
                            }}
                          >
                            {!cropVideoOn ? (
                              <i class="bi bi-crop"></i>
                            ) : (
                              <i class="bi bi-x-lg"></i>
                            )}
                          </div>
                          {videoCropProcessing && (
                            <p className="ir-chat-crop-processing-text">
                              Processing will take few minutes...
                            </p>
                          )}
                          {cropVideoOn && (
                            <div className="ir-ws-text-center ir-ws-default-btn-container trim-upload-btn">
                              <button
                                className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn"
                                disabled={videoCropProcessing}
                                onClick={handleCrop}
                                style={{ width: "200px" }}
                              >
                                <span>
                                  {videoCropProcessing
                                    ? "Processing..."
                                    : "Crop Video"}
                                </span>
                              </button>
                            </div>
                          )}

                          {!cropVideoOn && (
                            <div className="ir-ws-text-center ir-ws-default-btn-container trim-upload-btn">
                              <button
                                className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn"
                                disabled={disableUploadBtn}
                                onClick={submitImage}
                              >
                                <span>Send</span>
                              </button>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {openAttachDocsModal && (
            <div className="ir-ws-position-fixed ir-ws-sign-popup-container ir-attach-document-modal">
              <div className="ir-ws-position-absolute ir-bg-white ir-ws-sign-popup-inner-container">
                <div className="ir-ws-signup-content-container">
                  <div
                    className="ir-ws-position-absolute ir-ws-signup-close-container"
                    onClick={attachDocsCloseModalHandle}
                  >
                    <i className="bi bi-x-lg"></i>
                  </div>
                  <div className="ir-ws-signup-content-inner-container">
                    <h3 className="ir-ws-text-center ir-ws-heading-default-color ir-ws-signup-content-title">
                      Upload Documents
                    </h3>
                  </div>
                  <div className="ir-share-modal-inside-content">
                    <p className="ir-ws-app-color ir-ws-font-size-xs">
                      {selectedDoc.name}
                    </p>
                    <div className="ir-ws-text-center ir-share-container">
                      <img
                        className="ir-ws-block ir-ws-margin-o-auto ir-modal-chat-preview"
                        src={require("../../../assets/images/chat-images/document-icon.png")}
                        alt="file-preview"
                      />
                      <span className="ir-ws-block ir-ws-font-size-xxs ir-chat-modal-file-size">
                        {selectedDoc.size / 1000} kB
                      </span>
                    </div>
                    <div className="ans-privacy-upoadtn-con">
                      {/* <div className="create-question__checkbox-container">
                        <label className="create-question__checkbox-label">
                        <input
                          className="create-question__checkbox"
                          type="checkbox"
                          name="answerPrivacy"
                          checked={answerPrivacy}
                          onChange={(e) => setAnswerPrivacy(e.target.checked)}
                         />
                        Answer Privacy
                       </label>
                        </div> */}

                      <div className="ir-ws-text-center ir-ws-default-btn-container">
                        <button
                          className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn"
                          onClick={uploadDocument}
                        >
                          <span>Send</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {openImageGalleryModal && (
            <div className="iks-mint-cust-modal ir-chat-inside-modal-container ir-image-preview-main-modal">
              <div className="iks-mint-modal-container">
                <div className="ir-thumb-image-inner-container">
                  <div className="ir-image-large-thumb-title-container">
                    <h3>{activeCaseName}</h3>
                    <div className="ir-image-head-thumb-right">
                      <button onClick={closeImageGalleryModal}>
                        <i className="bi bi-x-lg"></i>
                      </button>
                    </div>
                  </div>
                  <div className="ir-modal-thumb-container-large-image">
                    {chatHistory?.[props.activeChatRoom] &&
                      chatHistory[props.activeChatRoom].length > 0 &&
                      chatHistory[props.activeChatRoom]
                        .filter((i) => i.images || i.videos)
                        .map((item, i) => {
                          const isImage =
                            item.images &&
                            /\.(jpg|jpeg|png|gif|bmp|webp)$/i.test(item.images);
                          const isVideo =
                            item.videos &&
                            /\.(mp4|mov|wmv|avi|webm|mkv)$/i.test(item.videos);

                          return (
                            <div key={i}>
                              <div
                                className={`iks-cine-content iks-cine-content-${i} ${
                                  i === carouselActiveIndex
                                    ? "iks-carousel-active"
                                    : "iks-carousel-inactive"
                                }`}
                              >
                                <AssetRender
                                  data={{
                                    images: isImage ? item.images : "",
                                    videos: isVideo ? item.videos : "",
                                  }}
                                  imageClicked={(data) => {
                                    if (isImage) onCarouselMenuItemclick(i);
                                    if (isVideo) onCarouselMenuItemclick(i);
                                  }}
                                  options={{
                                    fullScreen: false,
                                    height: "100%",
                                    videoControl: true,
                                  }}
                                />

                                {isImage && (
                                  <div className="chat-img-edit-btn">
                                    <button
                                      className="btn"
                                      onClick={() =>
                                        handleEditClick(item.images)
                                      }
                                    >
                                      <i className="bi bi-pencil"></i>
                                    </button>
                                  </div>
                                )}
                              </div>
                            </div>
                          );
                        })}

                    <button
                      onClick={leftCarouselIndex}
                      disabled={prevButtonDisable}
                      className={`ir-left-car-btn ir-carousel-action-btns`}
                    >
                      <i className="bi bi-chevron-left"></i>
                    </button>
                    <button
                      onClick={rightCarouselIndex}
                      disabled={nextButtonDisable}
                      className={`ir-right-car-btn ir-carousel-action-btns`}
                    >
                      <i className="bi bi-chevron-right"></i>
                    </button>
                  </div>
                  <div className="ir-thumbanil-modal-image">
                    {chatHistory?.[props.activeChatRoom] &&
                      chatHistory[props.activeChatRoom].length > 0 &&
                      chatHistory[props.activeChatRoom]
                        .filter((i) => i.images || i.videos)
                        .map((item, i) => {
                          const isImage =
                            item.images &&
                            /\.(jpg|jpeg|png|gif|bmp|webp)$/i.test(item.images);
                          const isVideo =
                            item.videos &&
                            /\.(mp4|mov|wmv|avi|webm|mkv)$/i.test(item.videos);

                          return (
                            <div
                              key={i}
                              onClick={() => onCarouselMenuItemclick(i)}
                              className={`ir-car-item-container ${
                                i === carouselActiveIndex
                                  ? "iks-carousel-item-active"
                                  : "iks-carousel-item-inactive"
                              }`}
                            >
                              <AssetRender
                                data={{
                                  images: isImage ? item.images : "",
                                  videos: isVideo ? item.videos : "",
                                }}
                                imageClicked={(data) =>
                                  onCarouselMenuItemclick(i)
                                }
                                options={{
                                  fullScreen: false,
                                  videoControl: false,
                                }}
                                className="ir-car-item-img"
                              />
                            </div>
                          );
                        })}
                  </div>
                </div>
              </div>
            </div>
          )}
          {isPopupOpen && (
            <div className="poll-popup">
              <div className="poll-popup-content">
                <div className="poll-header">
                  <h2 className="poll-heading">Create Poll</h2>
                </div>
                <label className="poll-question-label">
                  Question
                  <input
                    type="text"
                    className="poll-question-input"
                    value={question}
                    onChange={handleQuestionChange}
                    placeholder="Ask question"
                  />
                </label>
                <div className="poll-options-container">
                  <label className="poll-question-label">Options</label>
                  <div className="poll-options-list">
                    {options.map((option, index) => (
                      <div key={index} className="poll-option-wrapper">
                        <div className="option-input-cont">
                          <input
                            type="text"
                            className="poll-option-input"
                            value={option}
                            onChange={(e) =>
                              handleOptionChange(index, e.target.value)
                            }
                            placeholder={`Option ${index + 1}`}
                          />
                          {duplicateError === index && (
                            <p className="poll-error-message">
                              Option "{option}" already exists.
                            </p>
                          )}
                        </div>
                        <i
                          className="bi bi-trash poll-option-delete-icon"
                          onClick={() => handleDeleteOption(index)}
                        ></i>
                      </div>
                    ))}
                    {options.length < 4 && (
                      // <button
                      // className="btn btn-secondary poll-add-option-btn"
                      // onClick={handleAddOption}
                      // >
                      // Add Option
                      // </button>

                      <button
                        className="btn btn-primary bg-app-default-color btn-lg btn-block ir-btn-default ir-create-case-btn"
                        onClick={handleAddOption}
                        style={{ width: "170px" }}
                      >
                        <span>Add Option</span>
                      </button>
                    )}
                  </div>
                </div>
                <div className="poll-buttons-container">
                  <div className="poll-submit-button">
                    <button
                      className="btn btn-primary bg-app-default-color btn-lg btn-block ir-btn-default ir-create-case-btn"
                      id="newChat"
                      onClick={handlePollCreationSubmit}
                    >
                      <span>
                        {/* <i className="bi bi-bar-chart"></i> */}
                        Create
                      </span>
                    </button>
                  </div>
                  {/* <button className="poll-cancel-button" onClick={togglePollPopup}>
                    X
                    </button> */}
                  <i
                    class="bi bi-x-lg poll-cancel-button"
                    onClick={togglePollPopup}
                  ></i>
                </div>
              </div>
            </div>
          )}

          {isQuestionPopupVisible && (
            <div
              className="create-question__popup"
              // onClick={(e) => {
              //   if (e.target.classList.contains("create-question__popup")) {
              //     toggleQuestionPopupVisibility();
              //   }
              // }}
            >
              <div className="create-question__popup-content h70">
                <h3 className="create-question__popup-title">
                  Create Question
                </h3>

                <textarea
                  className="create-question__textarea"
                  name="questionText"
                  placeholder="Enter your question here"
                  value={questionDetails.questionText}
                  onChange={handleQuestionInputChange}
                  onInput={(e) => {
                    e.target.style.height = "auto";
                    e.target.style.height = `${e.target.scrollHeight}px`;
                  }}
                  rows={4}
                />

                {/* Image Preview */}
                {questionDetails.questionImagePreview && (
                  <div className="create-question__image-preview">
                    <img
                      src={questionDetails.questionImagePreview}
                      alt="Uploaded"
                      className="create-question__preview-img"
                    />
                    <button
                      className="create-question__remove-img"
                      onClick={handleImageRemove}
                    >
                      &times;
                    </button>
                  </div>
                )}

                {/* File Input */}
                <div className="create-question__file-input-container">
                  <input
                    id="questionFile"
                    className="create-question__file-input"
                    type="file"
                    accept="image/*"
                    onChange={handleQuestionImageUpload}
                  />
                  <label
                    htmlFor="questionFile"
                    className="create-question__file-label"
                  >
                    <i className="bi bi-upload me-1"></i> Upload Image
                  </label>
                </div>

                {((profile?.firstname === "admin" &&
                  profile?.lastname === "admin") ||
                  activeRooms?.createdBy === profile.username) && (
                  <div className="create-question__checkbox-container">
                    <label className="create-question__checkbox-label">
                      <input
                        className="create-question__checkbox"
                        type="checkbox"
                        name="isPrimary"
                        checked={questionDetails.isPrimary}
                        onChange={handleQuestionInputChange}
                      />
                      Is Primary
                    </label>
                  </div>
                )}

                {/* <div className="create-question__checkbox-container">
                                    <label className="create-question__checkbox-label">
                                    <input
                                        className="create-question__checkbox"
                                        type="checkbox"
                                        name="answerPrivacy"
                                        checked={answerPrivacy}
                                        onChange={(e) => setAnswerPrivacy(e.target.checked)}
                                    />
                                    Answer Privacy
                                    </label>
                                </div> */}

                <div className="create-question__actions">
                  {/* <button
                                            className="create-question__submit-button"
                                            onClick={handleQuestionSubmit}
                                        >
                                            Submit
                                        </button> */}

                  <div className="poll-submit-button">
                    <button
                      className="btn btn-primary bg-app-default-color btn-lg btn-block ir-btn-default ir-create-case-btn"
                      id="newChat"
                      onClick={handleQuestionSubmit}
                    >
                      <span>
                        <i className="bi bi-chat-dots me-1"></i> Create
                      </span>
                    </button>
                  </div>

                  {/* <button
                                className="create-question__cancel-button"
                                onClick={toggleQuestionPopupVisibility}
                            >
                                X
                            </button> */}
                  <i
                    class="bi bi-x-lg poll-cancel-button"
                    onClick={toggleQuestionPopupVisibility}
                  ></i>
                </div>
              </div>
            </div>
          )}

          {isSearchMessageByUsernamePopop && (
            <div className="ir-chat-view-more-modal-overlay">
              <div className="ir-chat-view-more-modal-container">
                <button
                  className="ir-chat-view-more-modal-close-btn"
                  onClick={toggleSearchMessagesByUserPopup}
                >
                  &times;
                </button>
                <h3 className="ir-chat-view-more-modal-title">
                  Filter messages by username
                </h3>
                {/* <input
            type="text"
            placeholder="Enter user name..."
            className="ir-chat-view-more-modal-input"
          />
          <button className="ir-chat-view-more-modal-submit-btn">Search</button> */}

                <ul className="ir-chat-view-more-modal-user-list">
                  {groupUsers?.users?.length > 0 ? (
                    groupUsers?.users?.map((user, i) => (
                      <li
                        key={i}
                        className="ir-chat-view-more-modal-user-item"
                        onClick={() => {
                          setFilteredUser(user?.userName || "Deleted User");
                          toggleSearchMessagesByUserPopup();
                        }}
                      >
                        {user?.userName ? user?.userName : "Deleted user"}
                      </li>
                    ))
                  ) : (
                    <p className="ir-chat-view-more-modal-empty">
                      No users found.
                    </p>
                  )}
                </ul>
              </div>
            </div>
          )}
        </>
      )}
      {showModal && (
        <DeleteConfirmation
          toggleModal={handleModalToggle}
          confirmationFunc={() => {
            handleDeleteClick(itemToDelete);
          }}
        />
      )}

      {isImageEditorOpen && (
        <ImageEditorModal
          isOpen={isImageEditorOpen}
          onClose={() => setIsImageEditorOpen(false)}
          onSave={handleSaveEditedImage}
          uploadEditedImage={uploadEditedImage}
          closeImageGalleryModal={closeImageGalleryModal}
        />
      )}
    </div>
  );
};

export default ChatContent;
