// DeleteAccountPage.js
import React, { useState } from "react";
import axios from "../../api/axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const DeleteAccountPage = () => {
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleDeleteAccount = async () => {
    setLoading(true);
    try {
      const userId = localStorage.getItem("userId");

      if (!userId) {
        throw new Error("User ID is missing.");
      }

      const url = `https://backend.ir4u.info/api/v1/user/delete-account/${userId.trim()}`;

      const response = await fetch(url, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwtTokenApi1")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          password: password,
        }),
      });

      // If the request is successful
      if (response.ok) {
        localStorage.removeItem("jwtTokenApi1");
        localStorage.removeItem("userId");
        localStorage.removeItem("loggedInUsername");
        localStorage.removeItem("isLoggedIn");
        const data = await response.json();
        Swal.fire({
          title: "Success!",
          text: "Account successfully deleted!",
          icon: "success",
        });

        navigate("/signin");
      } else {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to delete account.");
      }
    } catch (error) {
      setError("Failed to delete account. Please try again.");
      console.error("Error deleting account", error);

      Swal.fire({
        title: "Error",
        text: error.message || "An error occurred while deleting the account.",
        icon: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="ir-ws-sign-in-main-container">
      <div className="ir-ws-signin-inner-container ir-ws-sign-inner-container">
        {/* {!checkMail && ( */}
        <div className="ir-ws-sign-in-content-container">
          <div className="ir-ws-signin-logo-container">
            <div className="ir-ws-text-center ir-ws-signin-logo-inner-container">
              <a href="/">
                <img
                  className="ir-ws-signin-logo-img"
                  src={require("../../assets/images/ir4u2.png")}
                  alt="logo"
                />
              </a>
            </div>
          </div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleDeleteAccount();
            }}
          >
            <div className="ir-ws-signup-content-inner-container">
              <h3
                className="ir-ws-text-center ir-ws-heading-default-color ir-ws-signup-content-title"
                style={{
                  fontSize: "18px",
                  lineHeight: "1.5",
                  marginTop: "30px",
                }}
              >
                Enter password to delete your account
              </h3>
            </div>
            <div className="ir-ws-signup-form-container">
              <div className="ir-ws-signup-content-group">
                <input
                  className={"ir-ws-signup-input-field"}
                  required="required"
                  autoComplete="off"
                  type="password"
                  id="password"
                  value={password}
                  onChange={handlePasswordChange}
                />
                <label className="ir-ws-signup-label">password</label>
                <span className="ir-ws-signup-highlight"></span>
                <span className="ir-ws-signup-bar"></span>
              </div>
              <div className="ir-ws-text-center ir-ws-signup-form-submit-container">
                <button
                  className="ir-ws-app-bg ir-ws-no-border ir-color-white ir-ws-sign-submit-btn"
                  disabled={loading}
                >
                  {loading ? "Deleting..." : "Delete Account"}
                </button>
              </div>
            </div>
          </form>
        </div>
        {/* )} */}
      </div>
    </div>
  );
};

export default DeleteAccountPage;
