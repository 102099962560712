import React, { useEffect, useState, useContext } from "react";
import axios from "../../api/axios";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { RootStore } from "../../store/store";
import ChatContent from "../../components/Chat/ChatContent/ChatContent";
import ChatNavigation from "../../components/Chat/ChatNavigation/ChatNavigation";
import ChatProfile from "../../components/Chat/ChatProfile/ChatProfile";
import ChatSidebar from "../../components/Chat/ChatSidebar/ChatSidebar";
import "./ChatRoom.scss";
import ChatVerifyEmail from "./ChatVerifyEmail";

let roomHistoryInterval = null;

const ChatRoom = (props) => {
  const routeParams = useParams();
  let location = useLocation();
  // console.log("location", location);

  const navigate = useNavigate();
  const [store, setStore] = useContext(RootStore);
  const [token, setToken] = useState("");
  const [chatRooms, setChatRooms] = useState([]);
  const [chatData, setChatData] = useState([]);
  const [activeChatRoom, setActiveChatRoom] = useState(null);
  const [navMenuItemActive, setNavMenuItemActive] = useState(0);
  const { userStore, chatStore, socketConnection } = store;
  const [isChatLoading, setIsChatLoading] = useState(false);

  const [updateChatRoomFlag, setUpdateChatRoomFlag] = useState(false);
  const [isEmailVerified, setIsemailverified] = useState(false);

  const fetchData = async () => {
    const isAdmin = localStorage.getItem("loggedInUsername") === "admin";
    try {
      const response = await axios.get(
        "https://backend.ir4u.info/api/v1/user/profile",
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
            "Content-Type": "application/json",
          },
        }
      );
      console.log("response", response.data.isEmailVerified);
      userStore.profile = response?.data;
      setStore({ ...store, userStore });
      if (!response.data.isEmailVerified && !isAdmin) {
        // console.log('hi this is saheb and soon')
        setIsemailverified(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("jwtTokenApi1");
    fetchData();
    if (!token) {
      navigate("/signin?redirect=chat-room");
    } else {
      setToken(token);
      // getProducts() commented for the reason because of this line the chat is not loading in the begining of the page need to fix it
      getChatRooms();
      if (Object.keys(userStore.profile).length <= 0) {
        // getProfile();
        fetchData();
      }
    }
    //if routeparams.id exists, history.replace keep chat-room
  }, []);

  // useEffect(() => {
  //   const token = localStorage.getItem("jwtTokenApi2");
  //   fetchData();
  //   setToken(token);
  //   // getProducts() commented for the reason because of this line the chat is not loading in the begining of the page need to fix it
  //   getChatRooms();
  //   if (Object.keys(userStore.profile).length <= 0) {
  //     getProfile();
  //   }
  // }, []);

  //   useEffect(() => {
  //     console.log("Socket", props.socket);
  //     if (!props.socket.connected) {
  //       props.socket.connect();
  //     }
  //   }, [props.socket]);

  //   const getProfile = async () => {
  //     const response = await axios({
  //       url: "user/profile",
  //       method: "get",
  //       withCredentials: false,
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: "Bearer " + localStorage.getItem("jwtTokenApi2"),
  //       },
  //     });
  //     console.log("Get Chat History", response);
  //     // setChatRooms(response?.data?.data);
  //     userStore.profile = response?.data?.data;
  //     setStore({ ...store, userStore });
  //   };

  const getChatRooms = async () => {
    const response = await axios({
      url: "chat/group",
      method: "get",
      withCredentials: false,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
      },
    });
    console.log("Get Chat History", response);
    let chatData = response?.data?.data || [];
    let chatHistoryObject = {};
    let chatHistory = { ...chatStore.chatHistory };
    console.log("%c Get Chat History", { color: "#c00" });
    chatData.map((d) => {
      // creatign default chat history for all the grups
      if (!Object.keys(chatHistory).includes(d.id)) {
        chatHistoryObject[d.id] = [];
      }

      d.image = d.image.indexOf(",") ? d.image.split(",") : [d.image];
      d["images"] = [...d.image];
      d["deleteImages"] = [];
      return d;
    });

    setChatRooms(chatData);

    chatStore.groups = chatData;
    chatStore.chatHistory = { ...chatHistoryObject };

    setStore({ ...store, chatStore: { ...chatStore } });

    if (chatData.length > 0) {
      //   let id =
      //     routeParams?.id && routeParams.id !== ""
      //       ? routeParams.id
      //       : chatData[chatData.length - 1].id;

      let id =
        routeParams?.id && routeParams.id !== ""
          ? routeParams.id
          : chatData[0]?.id;

      setActiveChatRoom(parseInt(id));
      getChatHistory(id);
      // navigate('/chat-room/' + id);
    }
  };

  useEffect(() => {
    if (activeChatRoom != null) {
      getChatHistory(activeChatRoom);
    }
  }, [activeChatRoom]);

  const updateChatRoom = (data) => {
    setUpdateChatRoomFlag(data);
  };

  const getChatHistory = async (id) => {
    setIsChatLoading(true);
    setActiveChatRoom(id);
    const response = await axios({
      url: "chat/history/" + id,
      method: "get",
      withCredentials: false,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
      },
    });
    setIsChatLoading(false);
    console.log("Get Chat History", response);
    let chatHistoryData = { ...chatStore.chatHistory };
    if (!Object.keys(chatHistoryData).includes(id)) {
      chatHistoryData[id] = [];
    }
    chatHistoryData[id] = response?.data?.data;
    setStore({
      ...store,
      chatStore: {
        ...chatStore,
        chatHistory: { ...chatHistoryData },
      },
    });

    setChatData(chatHistoryData);
    //    console.log("chatHistoryData", chatHistoryData)
    // props.socket.emit('join_room', id);
    console.log("data is ", chatStore.chatHistory);
  };

  const getProducts = async () => {
    try {
      let response = await axios({
        url: "payment/product",
        method: "get",
        withCredentials: false,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Barier " + token,
        },
      });
      //   debugger
      response = response.data;
      if (response?.status === 200) {
        let allProducts =
          (response?.data?.length > 0 &&
            response?.data?.filter((product) => product.active == true)) ||
          [];
        let groups = [...chatStore.groups];
        if (groups?.length > 0) {
          groups.map((group) => {
            let index = allProducts.findIndex(
              (product) => product.name === group.name
            );
            if (index !== -1) {
              group["paymentInfo"] = allProducts[index];
            }
            return group;
          });
        }
        setChatRooms(groups);

        chatStore.groups = groups;
        setStore({ ...store, chatStore: { ...chatStore } });
      }

      //* check response status code ==200
      //* filter the response active = true
      // get chat room object from context
      // map chat room with products based on room name
      // store product as a value(for a key stripeProducts) within chat room object

      // const response = await fetch("http://3.84.171.136:5000/payment/product");
      // const json = await response.json();
      console.log("Get payment products", response);
    } catch (err) {
      console.error(err);
    }
  };

  // useEffect(() => {
  // 	getProducts();
  // }, []);

  return (
    <div className="ir-ws-flex ir-bg-white ir-chat-room-main-container">
      <ChatNavigation
        navMenuItemActive={navMenuItemActive}
        setNavMenuItemActive={setNavMenuItemActive}
      />
      <ChatSidebar
        // socket={props.socket}
        data={chatStore.groups}
        getChatHistory={getChatHistory}
        activeChatRoom={activeChatRoom}
        updateActiveChat={setActiveChatRoom}
        navMenuItemActive={navMenuItemActive}
        updateChatRoom={updateChatRoom}
        updateChatRoomFlag={updateChatRoomFlag}
        isChatLoading={isChatLoading}
        updateChatLoading={setIsChatLoading}
        setNavMenuItemActive={setNavMenuItemActive}
      />
      <div className="ir-ws-flex ir-ws-width-100 ir-split">
        <ChatContent
          //   socket={props.socket}
          data={chatStore.chatHistory}
          activeChatRoom={activeChatRoom}
          navMenuItemActive={navMenuItemActive}
          updateActiveChat={setActiveChatRoom}
          profile={userStore.profile}
          getChatHistory={getChatHistory}
          isChatLoading={isChatLoading}
          updateChatLoading={setIsChatLoading}
          chatData={chatData}
          getChatRooms={getChatRooms}
        />
        <ChatProfile
          activeChatRoom={activeChatRoom}
          updateChatRoom={updateChatRoom}
          updateChatRoomFlag={updateChatRoomFlag}
          getChatHistory={getChatHistory}
          chatData={chatData}
        />
      </div>
      {isEmailVerified && <ChatVerifyEmail />}
    </div>
  );
};

export default ChatRoom;
