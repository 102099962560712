import React, { useState } from "react";
import axios from "axios";
import "./ArchiveAdminDataModal.scss";
import { BaseURL } from "../../../api/axios";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { usePaymentContext } from "../../../context/PaymentContext";
import { useModalContext } from "../../WebinarAdminCreateWebinarTab/ModalContext";

const ArchiveAdminDataModal = ({
  closeModal,
  // activeRoomsData,
  addToArchieve,
  activeRoomId,
}) => {
  const { activeRoomsData, setActiveRoomsData } = useModalContext();
  console.log("Archive Admin Data Modal activeRoomsData:", activeRoomsData);
  const [imgUrl, setImgUrl] = useState(null);
  const [itemId, setItemsId] = useState(null);
  const [title, setTitle] = useState(null);
  const [currency, setCurrency] = useState("inr");
  const [price, setPrice] = useState("");

  const { paymentResponses, setPaymentResponses } = usePaymentContext();

  useEffect(() => {
    // setImgUrl(activeRoomsData?.image);
    setTitle(activeRoomsData?.name);
    setItemsId(activeRoomsData?.id);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // const formData = new FormData();
    // // formData.append("image", imgUrl);
    // formData.append("name", title);
    // formData.append("price", price);
    // // formData.append("currency", "inr");
    // formData.append("quantity", 1);
    // // formData.append("id", itemId);

    // const headers = {
    //   Authorization: "Bearer " + localStorage.getItem("jwtTokenApi2"),
    //   "Content-Type": "multipart/form-data",
    // };

    // axios
    //   .post(`${BaseURL}/payment/product`, formData, { headers })
    //   .then((response) => {
    //     console.log("Product created:", response?.data);

    //     // const newPaymentResponses = {
    //     //   ...paymentResponses,
    //     //   [activeRoomsData?.data?.data?.id]: response?.data?.data?.paymentLink,
    //     // };
    //     // console.log("New Payment Responses:", newPaymentResponses);

    //     // setPaymentResponses(newPaymentResponses);

    //     // console.log("Payment responses updated:", paymentResponses);

    //     const newPaymentResponses = {
    //       ...paymentResponses,
    //       [itemId]: response?.data?.data?.paymentLink,
    //     };
    //     setPaymentResponses(newPaymentResponses);
    //     console.log("new payment id", newPaymentResponses);

    //     localStorage.setItem(
    //       "paymentResponses",
    //       JSON.stringify(newPaymentResponses)
    //     );

    //     closeModal();
    //     Swal.fire({
    //       title: "Success!",
    //       text: "Room is successfully archived",
    //       icon: "success",
    //     });
    //   })
    //   .catch((error) => {
    //     console.error("Error creating product:", error);
    //   });

    console.log("Submit data", title, price, currency);

    await addToArchieve(activeRoomId, price);
  };

  // const handleCancel = () => {};
  return (
    <>
      <div className="ir-ws-position-fixed ir-ws-sign-popup-container">
        <div className="ir-ws-position-absolute ir-bg-white ir-ws-sign-popup-inner-container">
          <div className="ir-ws-signup-content-container ">
            <div
              className="ir-ws-position-absolute ir-ws-signup-close-container"
              onClick={closeModal}
            >
              <i className="bi bi-x-lg"></i>
            </div>
            <div className="ir-ws-signup-content-inner-container">
              <h3 className="ir-ws-text-center ir-ws-heading-default-color ir-ws-signup-content-title">
                Archive Case
              </h3>
            </div>

            <form
              className={`ir-ws-signup-form-container ir-ws-chat-create-case-cont`}
              onSubmit={handleSubmit}
            >
              <p className="archive-case-price">{title}</p>
              <p className="archive-case-description">
                {activeRoomsData?.description}
              </p>

              <div className="ir-ws-signup-content-group">
                <input
                  className="ir-ws-signup-input-field"
                  type="number"
                  // name="name"
                  // type="text"
                  value={price}
                  onChange={(e) => setPrice(parseInt(e.target.value))}
                  required
                />
                <span className="ir-ws-signup-highlight"></span>
                <span className="ir-ws-signup-bar"></span>
                <label className="ir-ws-signup-label">Case Price</label>
              </div>

              <div className="ir-ws-text-center ir-ws-default-btn-container">
                <button
                  className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn"
                  // disabled={!agree || createCaseLoading}
                  type="submit"
                >
                  <span>Archive Case</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ArchiveAdminDataModal;
