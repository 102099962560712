import React, { useContext, useEffect, useState } from "react";
import ChatAdminRoomDashboard from "./ChatAdminRoomDashboard";
import { useLocation, useNavigate } from "react-router-dom";
import { RootStore } from "../../store/store";
import axios from "../../api/axios";

const ChatAdminRoomTab = (props) => {
  let location = useLocation();
  // console.log("location", location);

  const navigate = useNavigate();
  const [store, setStore] = useContext(RootStore);
  const [token, setToken] = useState("");
  const [chatRooms, setChatRooms] = useState([]);
  const [activeChatRoom, setActiveChatRoom] = useState(null);
  const [navMenuItemActive, setNavMenuItemActive] = useState(0);
  const { userStore, chatStore, socketConnection } = store;
  const [isChatLoading, setIsChatLoading] = useState(false);
  const { profile, users } = userStore;

  useEffect(() => {
    const token = localStorage.getItem("jwtTokenApi1");
    if (!token) {
      navigate("/signin");
    } else {
      navigate("/admin");
      setToken(token);
      getChatRooms();

      //   setTimeout(() => getUserProfiles(), 400);

      if (Object.keys(userStore?.profile)?.length <= 0) {
        getProfile();
      }
    }
    //if routeparams.id exists, history.replace keep chat-room
  }, []);

  //   useEffect(() => {
  //     console.log("Socket", props.socket);
  //     if (!props.socket.connected) {
  //       props.socket.connect();
  //     }
  //   }, [props.socket]);

  const getProfile = async () => {
    const response = await axios({
      url: "user/profile",
      method: "get",
      withCredentials: false,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
      },
    });
    console.log("Get Chat History", response);
    // setChatRooms(response?.data?.data);
    userStore.profile = response?.data?.data;
    setStore({ ...store, userStore });
  };

  const getChatRooms = async () => {
    const response = await axios({
      url: "chat/group",
      method: "get",
      withCredentials: false,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
      },
    });
    console.log("Get Chat History", response);
    let chatData = response?.data?.data || [];
    let chatHistoryObject = {};
    let chatHistory = { ...chatStore.chatHistory };
    console.log("%c Get Chat History", { color: "#c00" });
    chatData.map((d) => {
      // creatign default chat history for all the grups
      if (!Object.keys(chatHistory).includes(d.id)) {
        chatHistoryObject[d.id] = [];
      }

      d.image = d.image.indexOf(",") ? d.image.split(",") : [d.image];
      d["images"] = [...d.image];
      d["deleteImages"] = [];
      return d;
    });

    setChatRooms(chatData);

    chatStore.groups = chatData;
    chatStore.chatHistory = { ...chatHistoryObject };

    setStore({ ...store, chatStore: { ...chatStore } });

    // if (chatData.length > 0) {
    // 	let id = (routeParams?.id && routeParams.id !== "") ? routeParams.id : chatData[chatData.length - 1].id;
    // 	setActiveChatRoom(parseInt(id));
    // 	getChatHistory(id);
    // 	// navigate('/chat-room/' + id);
    // }
  };

  //   const getUserProfiles = async () => {
  //     const response = await axios({
  //       url: "user/all",
  //       method: "get",
  //       withCredentials: false,
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
  //       },
  //     });
  //     // debugger;
  //     console.log("Get all User Profiles", response);
  //     let allUsers = [...response?.data?.data];
  //     let allGroups = [...chatStore.groups];
  //     allUsers.map((user, ui) => {
  //       let userGroups = allGroups
  //         .filter((e) => Number(e.createdBy) === Number(user.id))
  //         .map((e) => e.name);
  //       user["groups"] = userGroups;
  //     });
  //     // let allUsersStore = {...userStore}
  //     userStore.users = allUsers;
  //     setStore({ ...store, userStore });
  //   };

  //   useEffect(() => {
  //     if (!props.socket.connected) {
  //       props.socket.connect();
  //     }
  //   });

  return (
    <div className="ir-ws-admin-create-webinar-tab ir-ws-webinar-tb-main-container ir-ws-admin-create-course-container">
      <ChatAdminRoomDashboard
        // socket={props.socket}
        data={chatStore.chatHistory}
        activeChatRoom={activeChatRoom}
        navMenuItemActive={navMenuItemActive}
        updateActiveChat={setActiveChatRoom}
        profile={userStore.profile}
        users={[...users]}
        isChatLoading={isChatLoading}
        updateChatLoading={setIsChatLoading}
        getChatRooms={getChatRooms}
      />
    </div>
  );
};

export default ChatAdminRoomTab;
