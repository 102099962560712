import React, { useContext, useEffect, useRef, useState } from "react";
import WebSocketService from "../../../webSocketService";
import { RootStore } from "../../../store/store";
import { useModalContext } from "../../WebinarAdminCreateWebinarTab/ModalContext";
import axios from "../../../api/axios";

const AssetRender = ({
  data,
  imageClicked,
  options = { width: 80, height: 80, videoControl: true },
}) => {
  const {
    showAllVoters,
    setShowAllVoters,
    handleViewVoters,
    setIsScrollToBottom,
    handleChatProfileOpen,
  } = useModalContext();

  const { sendVote } = WebSocketService();
  const [store, setStore] = useContext(RootStore);
  const { chatStore, userStore } = store;

  const [showVoters, setShowVoters] = useState(false);
  const [groupUsers, setGroupUsers] = useState([]);
  const voterListRef = useRef(null);

  const closeMenu = () => setShowVoters(false);

  const handleVote = (pollId, optionId) => {
    const username = userStore?.profile.username;
    // const profileImage = userStore?.profile.userImage;
    const profileImage = userStore?.profile?.userImage
      ? userStore?.profile?.userImage
      : "";

    sendVote(String(pollId), optionId, username, profileImage);
    setIsScrollToBottom(false);

    // console.log("This poll data", pollId, optionId, username, profileImage);
  };

  // console.log("data activeChatRoom", data, activeChatRoom);

  const renderImagesWithNotes = () => {
    return data.imageWithNotes?.map((item, index) => (
      <div key={index} className="ir-chat-image-with-note">
        <img
          className="chat-image"
          src={item.imageUrl}
          onClick={() => imageClicked(item.imageUrl)}
          height={options.height}
          alt={`Image ${index + 1}`}
        />

        {data.notes && <div className="clinical-notes">{data.notes}</div>}
      </div>
    ));
  };

  let renderMessage = "";

  if (data.message) {
    renderMessage = data.message;
  }

  if (data.images) {
    renderMessage = (
      <>
        <img
          className="chat-image"
          src={data.images}
          onClick={() => imageClicked(data.images)}
          height={options.height}
          alt="Image"
        />
        {data.clinicalNotes && (
          <div className="clinical-notes">{data.clinicalNotes}</div>
        )}
      </>
    );
  }

  //   if (data.messageType === "images-notes" && data.imageWithNotes?.length) {
  //     renderMessage = (
  //       <div className="images-notes-container">{renderImagesWithNotes()}</div>
  //     );
  //   }

  if (data.messageType === "images-notes" && data.imageWithNotes) {
    renderMessage = data.imageWithNotes.map((item, index) => (
      <div key={index} className="chat-message-container">
        <img
          className="chat-image"
          src={item.imageUrl}
          onClick={() => imageClicked(item.imageUrl)}
          height={options.height}
          alt={`Image with note ${index + 1}`}
        />
        {item.notes && <div className="clinical-notes">{item.notes}</div>}
      </div>
    ));
  }

  if (data?.videos) {
    renderMessage = (
      <div
        className="ir-video-icon-container"
        onClick={() => imageClicked(data.videos)}
      >
        <video
          height={options.height}
          loop={false}
          controls={options.videoControl}
        >
          <source src={data.videos} type="video/mp4" />
        </video>
        <i className="bi bi-play-circle"></i>
      </div>
    );
  }

  if (data.documents) {
    const ext = data.documents.split(".");
    const [, , , fileNameDetails, fileExtension] = ext;
    const fileName = fileNameDetails.split("/")[3];
    const truncatedFileName =
      data.documents.split("/").pop().slice(0, 18) +
      (data.documents.split("/").pop().length > 18 ? " .." : "");

    renderMessage = (
      <div className="ir-chat-list-container-documents">
        <div className="doc-file-type-container">
          <i
            className={`${
              fileExtension === "pdf"
                ? "bi-filetype-pdf"
                : fileExtension === "doc"
                ? "bi-filetype-doc"
                : fileExtension === "docx"
                ? "bi-filetype-docx"
                : fileExtension === "xlsx"
                ? "bi-filetype-xlsx"
                : "bi-filetype-xls"
            } doc-file-type bi`}
          ></i>
        </div>
        <div className="ir-chat-doc">{truncatedFileName}</div>
        <div className="ir-chat-doc">.{fileExtension}</div>
        <a
          href={data.documents}
          target="_blank"
          rel="noopener noreferrer"
          download
        >
          <i className="bi bi-download"></i>
        </a>
      </div>
    );
  }

  if (data.audio) {
    renderMessage = (
      <div className="ir-audio-container">
        <audio controls>
          <source src={data.audio} type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
      </div>
    );
  }

  if (data.messageType === "question" && data.question) {
    renderMessage = (
      <>
        {/* Render the image associated with the question if available */}
        {data.question.images?.length > 0 && (
          <img
            className="chat-image"
            src={data.question.images[0]}
            // onClick={() => imageClicked(data.question.images[0])}
            height={options.height}
            alt="Question Image"
          />
        )}
        {/* Render the question message */}
        {data.question.message && (
          <div
            className="clinical-notes"
            onClick={() =>
              console.log("clicked on the question:", data.question)
            }
          >
            <span className="question-heading-msg-t-ques">Question:</span>
            {data.question.message}
          </div>
        )}
      </>
    );
  }

  if (data.messageType === "poll" && data.poll) {
    const totalGroupUsers = 10;
    // const totalGroupUsers = groupUsers?.userCount || 0;
    renderMessage = (
      <div
        className={`ir-chat-poll ${
          userStore.profile.username === data.userName
            ? "sender-vote-poll"
            : "receiver-vote-poll"
        }`}
      >
        <div className="poll-question">{data.poll.pollText}</div>
        <div className="poll-options">
          {data?.poll?.pollOptions?.map((option, index) => {
            // Check if the current user has voted for this option
            const isSelected = option?.voters?.some(
              (voter) => voter.userName === userStore?.profile.username
            );

            const totalVotes = data.poll.pollOptions.reduce(
              (sum, opt) => sum + (opt.voteCount || 0),
              0
            );

            // Calculate the normalized width based on group users
            const normalizedWidth = totalGroupUsers
              ? Math.min((option?.voteCount / totalGroupUsers) * 100, 100)
              : 0;
            // const normalizedWidth = totalGroupUsers
            // ? Math.min((option?.voteCount / totalGroupUsers) * 100, 100)
            // : 0;

            // Calculate vote percentage for highlight fill
            const votePercentage = totalVotes
              ? Math.min((option?.voteCount / totalVotes) * 100, 100)
              : 0;

            return (
              <div key={index} className="poll-option">
                <div className="poll-radio">
                  <input
                    type="radio"
                    // type="checkbox"
                    name={`poll-${data?.poll.ID}`}
                    id={`option-${option?.ID}`}
                    // onClick={() => handleVote(data?.chatId, option.ID)}
                    onChange={() => handleVote(data?.chatId, option.ID)}
                    // checked={isSelected}
                    checked={option?.voters?.some(
                      (voter) => voter.userName === userStore?.profile.username
                    )}
                    readOnly // Prevent manual unchecking
                  />
                  <label htmlFor={`option-${option.ID}`} className="poll-label">
                    {option?.text}
                  </label>
                </div>

                <div className="poll-progress-bar-container">
                  <div
                    className="poll-progress-bar-bg"
                    style={{
                      width: "100%",
                    }}
                  >
                    <div
                      className="poll-progress-bar-fg"
                      style={{
                        width: `${normalizedWidth}%`,
                        backgroundColor:
                          option?.voteCount > 0 ? "#6842ef" : "#d3d3d3",
                      }}
                    ></div>
                  </div>
                </div>

                <span className="poll-vote-count">
                  {option?.voteCount || 0}
                </span>
              </div>
            );
          })}
        </div>
        <div className="poll-footer">
          {/* <span className="poll-view-votes">View votes</span>
           */}
          <span
            className="poll-view-votes"
            onClick={() => {
              handleViewVoters(data?.poll.ID);
              handleChatProfileOpen();
            }}
          >
            View votes
          </span>
        </div>
      </div>
    );
  }

  return <>{renderMessage}</>;
};

export default AssetRender;
