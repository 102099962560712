// import React from "react";
// import { ConfirmationTypes } from "../../../../constants";

// const ActiveRoomsTab = ({ data, onAction }) => {
//   return (
//     <div className="ir-chat-rooms-tab-container ir-active-rooms-tab-main-container">
//       <div className="ir-chat-admin-inside-tab-container">
//         {/* {JSON.stringify(data.groups)} */}
//         {data?.groups?.length > 0 &&
//           data?.groups?.filter((i) => i.status === "active")?.length === 0 && (
//             <p className="ir-found-no-results-text">
//               There are No Active Rooms
//             </p>
//           )}
//         <ul className="ir-chat-admin-tab-in-list-container">
//           {data?.groups
//             ?.filter((i) => i.status === "active")
//             .map((chatInfo, index) => {
//               return (
//                 <li
//                   className="ir-chat-admin-tab-in-list-item-container"
//                   key={index}
//                 >
//                   <div className="ir-chat-admin-tab-in-list-item">
//                     <div className="ir-chat-admin-tab-in-list-item-content-container ir-chat-admin-tab-in-list-item-check-container">
//                       <div className="ir-checkbox">
//                         <input id={index} type="checkbox" />
//                         <label htmlFor={index}></label>
//                       </div>
//                     </div>
//                     <div className="ir-chat-admin-tab-in-list-item-content-container">
//                       <img
//                         src={chatInfo?.image[0]}
//                         className="rounded-circle"
//                         alt="case chat icon"
//                         onError={({ currentTarget }) => {
//                           currentTarget.onerror = null;
//                           currentTarget.src = require("../../../../assets/images/chat-images/no-image.png");
//                         }}
//                       />
//                     </div>
//                     <div className="ir-chat-admin-tab-in-list-item-content-container ir-chat-admin-tab-in-list-item-more-width-container">
//                       <span>{chatInfo.name}</span>
//                     </div>
//                     <div className="ir-chat-admin-tab-in-list-actions-container">
//                       <div className="ir-chat-admin-tab-in-list-item-content-container">
//                         {chatInfo.archive === true ? (
//                           <button
//                             onClick={() => {
//                               onAction(chatInfo, ConfirmationTypes.UNARCHIVE);
//                             }}
//                           >
//                             Unarchive
//                           </button>
//                         ) : (
//                           <button
//                             onClick={() => {
//                               onAction(chatInfo, ConfirmationTypes.ARCHIVE);
//                             }}
//                           >
//                             Archive
//                           </button>
//                         )}
//                       </div>
//                       <div className="ir-chat-admin-tab-in-list-item-content-container">
//                         <button
//                           onClick={() => {
//                             onAction(chatInfo, ConfirmationTypes.DELETEROOM);
//                           }}
//                         >
//                           Delete
//                         </button>
//                       </div>
//                       <div className="ir-chat-admin-tab-in-list-item-content-container">
//                         {chatInfo.freeze === false ? (
//                           <button
//                             onClick={() => {
//                               onAction(chatInfo, ConfirmationTypes.FREEZE);
//                             }}
//                           >
//                             Freeze{" "}
//                           </button>
//                         ) : (
//                           <button
//                             onClick={() => {
//                               onAction(chatInfo, ConfirmationTypes.UNFREEZE);
//                             }}
//                           >
//                             Unfreeze{" "}
//                           </button>
//                         )}
//                       </div>
//                       <div className="ir-chat-admin-tab-in-list-item-content-container">
//                         <button
//                           onClick={() => {
//                             onAction(chatInfo, ConfirmationTypes.DELETECONTENT);
//                           }}
//                         >
//                           Delete Content
//                         </button>
//                       </div>
//                     </div>
//                   </div>
//                 </li>
//               );
//             })}
//         </ul>
//       </div>
//     </div>
//   );
// };

// /**
//  * const
//  * <button onClick={() => showConfirmModal(true)}>Freeze</button>
//  * <ShowModal data={data} buttons={[{name: "Confirm", action: freezeRoom}, {name: "Cancel", action: cancelModal}]}/>
//  *
//  * <Modal>
//  *  <Modal.Body>
//  *  <button>Confirm</button>
//  *  <button>Cancel</button>
//  * </Modal.Body>
//  * </Modal>
//  *
//  */

// export default ActiveRoomsTab;

import React from "react";
import { ConfirmationTypes } from "../../../../constants";
import { useModalContext } from "../../../WebinarAdminCreateWebinarTab/ModalContext";

const ActiveRoomsTab = ({ data, onAction, getChatRooms }) => {
  const { activeRoomsData, setActiveRoomsData } = useModalContext();
  console.log("data from active rooms tab admin:", data);

  return (
    <div>
      {data?.groups?.length > 0 &&
        data?.groups?.filter((i) => i.status === "active")?.length === 0 && (
          <p
            style={{
              textAlign: "center",
              fontSize: "1.6rem",
              marginTop: "50px",
              fontWeight: "700",
            }}
          >
            There are No Active Rooms
          </p>
        )}
      {data?.groups
        ?.filter((i) => i.status === "active")
        .map((chatInfo, index) => (
          <div className="ir-ws-webinar-list-tab" key={index}>
            <div className="ir-ws-admin-webinar-content">
              <div className="ir-ws-admin-webinar-title-container">
                <img
                  src={chatInfo?.image[0]}
                  className="chat-room-image-circle"
                  alt="case chat icon"
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = require("../../../../assets/images/chat-images/no-image.png");
                  }}
                />
                <p className="ir-ws-webinar-title-text">{chatInfo.name}</p>
              </div>
              <div className="ir-ws-admin-list-button-container">
                {chatInfo.archive === "true" ? (
                  <button
                    className="ir-ws-webinar-cancel-button ir-ws-webinar-actions-button"
                    onClick={() => {
                      setActiveRoomsData(chatInfo);
                      onAction(chatInfo, ConfirmationTypes.UNARCHIVE);
                    }}
                  >
                    Unarchive
                  </button>
                ) : (
                  <button
                    className="ir-ws-webinar-cancel-button ir-ws-webinar-actions-button"
                    onClick={() => {
                      setActiveRoomsData(chatInfo);
                      onAction(chatInfo, ConfirmationTypes.ARCHIVE);
                    }}
                  >
                    Archive
                  </button>
                )}
                <button
                  className="ir-ws-webinar-cancel-button ir-ws-webinar-actions-button"
                  onClick={() => {
                    onAction(chatInfo, ConfirmationTypes.DELETEROOM);
                  }}
                >
                  Delete
                </button>
                {chatInfo.freeze === false ? (
                  <button
                    className="ir-ws-webinar-cancel-button ir-ws-webinar-actions-button"
                    onClick={() => {
                      onAction(chatInfo, ConfirmationTypes.FREEZE);
                    }}
                  >
                    Freeze
                  </button>
                ) : (
                  <button
                    className="ir-ws-webinar-cancel-button ir-ws-webinar-actions-button"
                    onClick={() => {
                      onAction(chatInfo, ConfirmationTypes.UNFREEZE);
                    }}
                  >
                    Unfreeze
                  </button>
                )}
                <button
                  className="ir-ws-webinar-cancel-button ir-ws-webinar-actions-button"
                  onClick={() => {
                    onAction(chatInfo, ConfirmationTypes.DELETECONTENT);
                  }}
                >
                  Delete Content
                </button>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default ActiveRoomsTab;
